import { useSidebarContext } from "@/context/SidebarContextProvider";
import { DIRECTORY_ID_PREFIX } from "@/lib/constants";
import { FilePage, useAppHref } from "@/lib/path-utils";
import usePrevious from "@/lib/use-previous";
import { classNames } from "@/lib/utils";
import {
  DirectoryFile,
  Directory as DirectoryType,
  FileOrDirectory,
  directoryFileFromFile,
  renameDirectory,
  useDirectoryStructure,
} from "@/services/directories.service";
import { prefetchFile, renameFile, shouldWarnOnPathChange } from "@/services/files.service";
import { File as AppFile } from "@/types/app/file";
import { EventPropagationShield } from "@components/atoms/EventPropagationShield";
import { EntityIcon } from "@components/library/Entities/EntityIcon";
import IconButton from "@components/library/IconButton";
import { DirectoryModal } from "@components/llama/Projects/DirectoryModal";
import { FileRenameModal } from "@components/llama/Projects/FileRenameModal";
import { PlusIcon } from "@heroicons/react/outline";
import { CaretDown } from "@phosphor-icons/react";
import * as ContextMenu from "@radix-ui/react-context-menu";
import Link from "next/link";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NodeApi, NodeRendererProps } from "react-arborist";
import { NavItem } from "../NavItem";
import { shouldPreventDrop } from "./DirectoryTree";
import { FileNodeMenu, FileNodeMenuItems } from "./FileNodeMenu";

export const TREE_PADDING = 8;
export const TREE_INDENT = 12;

interface TreeNodeProps {
  indentLevel: number;
  dragHandle: ((el: HTMLDivElement | null) => void) | undefined;
}

interface DirectoryProps extends TreeNodeProps {
  directory: DirectoryTreeNode;
  isOpen: boolean;
  isSelected: boolean;
  isDragging: boolean;
  willReceiveDrop: boolean;
  onToggle?: () => void;
}

export const getFileSystemItemID = (id: string) => `sidebar-filesystem-${id}`;

const Directory = React.memo(
  ({
    directory,
    isOpen,
    isSelected,
    isDragging,
    willReceiveDrop,
    onToggle,
    indentLevel,
    dragHandle,
  }: DirectoryProps) => {
    const { renamingFileId, setRenamingFileId } = useSidebarContext();
    const [renameDialog, setRenameDialog] = useState({
      name: directory.name,
      open: false,
    });
    const { onChange } = useDirectoryStructure();
    const [isHandlingRenaming, setIsHandlingRenaming] = useState(false);

    const handleRename = useCallback(
      (newName: string) => {
        const openRenamingDialog = () => {
          setRenameDialog({
            name: newName,
            open: true,
          });
        };
        if (newName.trim() && newName.trim() !== directory.name && !isHandlingRenaming) {
          setIsHandlingRenaming(true);
          if (shouldWarnOnPathChange({ type: "directory", item: directory })) {
            openRenamingDialog();
          } else if (newName.trim()) {
            renameDirectory({
              directory,
              name: newName,
              onSuccess: (newDirectory) => {
                onChange([
                  {
                    type: "update",
                    item: { type: "directory", item: newDirectory },
                  },
                ]);
                setIsHandlingRenaming(false);
              },
              onError: () => {
                openRenamingDialog();
              },
            });
          }
        }
        setRenamingFileId(null);
      },
      [
        directory.id,
        isHandlingRenaming,
        onChange,
        renameDialog,
        setIsHandlingRenaming,
        setRenameDialog,
        setRenamingFileId,
      ],
    );

    const href = useAppHref({ type: "directory", id: directory.id });

    const toggleIfSelected = useCallback(() => {
      if (isSelected) {
        onToggle?.();
      }
    }, [isSelected, onToggle]);

    return (
      <div ref={dragHandle}>
        <ContextMenu.Root>
          <Link href={href} onClick={toggleIfSelected}>
            <ContextMenu.Trigger asChild>
              <div
                title={directory.name}
                className={classNames(
                  isDragging ? "!bg-transparent opacity-60" : "",
                  // This seems to be always true if something dragged on? It should be smarter and only true if its valid. :/
                  willReceiveDrop
                    ? "box-border border border-stroke-secondary-3 bg-background-secondary-2"
                    : "border-transparent",

                  "group/node ml-[7px] mr-[7px] flex h-28 items-center rounded-ms border  pl-8 pr-4",
                  isSelected ? "bg-background-base-35" : "hover:bg-background-base-325",
                )}
                // 8px 'margin' but with 1px spacing to the menu icon button and the border
                // 7px on right so can give 1px spacing to the menu icon button
                // 1px on left to account for the transparent border
                style={{ marginLeft: indentLevel * TREE_INDENT + TREE_PADDING - 1, marginRight: TREE_PADDING - 1 }}
              >
                <div className="flex w-full items-center gap-4">
                  <IconButton
                    size={16}
                    iconSize={20}
                    iconClassName={classNames(
                      "transition-transform duration-200 ease-in-out",
                      isOpen ? "" : "-rotate-90",
                    )}
                    Icon={CaretDown}
                    className={classNames(
                      "my-1 shrink-0  bg-background-base-35 ",
                      isSelected ? "text-icon-base-1" : "text-icon-base-1 group-hover/node:text-icon-base-1",
                    )}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation(); // Stops this node from being selected
                      onToggle?.();
                    }}
                  />
                  <div className="flex w-full items-center gap-4 truncate">
                    <span
                      className={classNames(
                        "w-full grow truncate text-12-16 text-text-base-2 group-hover/node:text-text-base-1",
                        isSelected && "font-bold",
                      )}
                    >
                      <EditableFileName
                        initialName={directory.name}
                        isEditing={renamingFileId === directory.id}
                        stopEditing={() => setRenamingFileId(null)}
                        handleRename={handleRename}
                        isHandlingRenaming={isHandlingRenaming}
                      />
                    </span>
                    <FileNodeMenu
                      file={directory}
                      selected={isSelected}
                      startRenaming={() => setRenamingFileId(directory.id)}
                      href={href}
                    />
                  </div>
                </div>
              </div>
            </ContextMenu.Trigger>
          </Link>
          <ContextMenu.Portal>
            <FileNodeMenuItems
              file={directory}
              selected={isSelected}
              menuType="context"
              startRenaming={() => setRenamingFileId(directory.id)}
              href={href}
            />
          </ContextMenu.Portal>
        </ContextMenu.Root>

        {renameDialog.open ? (
          <EventPropagationShield>
            <DirectoryModal
              mode="rename"
              directory={{ ...directory, name: renameDialog.name }}
              open={renameDialog.open}
              onRename={(newDirectory) => {
                onChange([
                  {
                    type: "update",
                    item: { type: "directory", item: newDirectory },
                  },
                ]);
              }}
              onClose={() => {
                setIsHandlingRenaming(false);
                setRenameDialog({ name: directory.name, open: false });
              }}
            />
          </EventPropagationShield>
        ) : null}
      </div>
    );
  },
);
Directory.displayName = "Directory";

interface FileProps extends TreeNodeProps {
  file: DirectoryFile;
  isSelected: boolean;
  isDragging: boolean;
}

const File = React.memo(({ file, isSelected, isDragging, indentLevel, dragHandle }: FileProps) => {
  const href = useAppHref({ type: "file", id: file.id });
  const { renamingFileId, setRenamingFileId } = useSidebarContext();
  const [renameDialog, setRenameDialog] = useState({
    name: file.name,
    open: false,
  });
  const { onChange } = useDirectoryStructure();
  const [isHandlingRenaming, setIsHandlingRenaming] = useState(false);

  const handleRename = (newName: string) => {
    const openRenamingDialog = () => {
      setRenameDialog({
        name: newName,
        open: true,
      });
    };
    // We allow renaming if the File isn't being used yet, otherwise we show the Dialog
    // which gives the user warning and errors etc.
    if (newName.trim() && newName.trim() !== file.name && !isHandlingRenaming) {
      setIsHandlingRenaming(true);
      if (shouldWarnOnPathChange({ type: "file", item: file })) {
        openRenamingDialog();
      } else if (newName.trim()) {
        renameFile({
          file,
          name: newName,
          onSuccess: (newFile: AppFile) => {
            onChange([
              {
                type: "update",
                item: {
                  type: "file",
                  item: directoryFileFromFile(newFile),
                },
              },
            ]);
            setIsHandlingRenaming(false);
          },
          onError: () => {
            openRenamingDialog();
          },
        });
      }
    }
    setRenamingFileId(null);
  };

  // Prefetch the file and keep it in the cache so there's
  // less of a loading delay when navigating to it.
  const handleMouseEnter = useCallback(() => {
    prefetchFile({ fileId: file.id });
  }, [file.id]);

  return (
    <div ref={dragHandle}>
      <ContextMenu.Root>
        <Link href={href} onMouseEnter={handleMouseEnter} shallow id={getFileSystemItemID(file.id)}>
          <ContextMenu.Trigger asChild>
            <div
              title={file.name}
              className={classNames(
                isDragging ? "opacity-60" : isSelected ? "bg-background-base-35" : "hover:bg-background-base-325",
                // 7px so can give 1px spacing to the menu icon button
                "group/node ml-8 mr-[7px] flex h-28 items-center rounded-ms pl-8 pr-4",
              )}
            >
              <div
                className="relative flex grow items-center gap-4 truncate"
                style={{ paddingLeft: indentLevel * TREE_INDENT }}
              >
                <EntityIcon type={file.type} size={16} />
                <div
                  className={classNames(
                    "relative w-full min-w-0",
                    "text-12-16",
                    "text-text-base-2 group-hover/node:text-text-base-1 group-data-[state=open]/node:text-text-base-1",
                    isSelected && "font-bold",
                  )}
                >
                  <EditableFileName
                    initialName={file.name}
                    isEditing={renamingFileId === file.id}
                    stopEditing={() => setRenamingFileId(null)}
                    handleRename={handleRename}
                    isHandlingRenaming={isHandlingRenaming}
                  />
                </div>
              </div>

              <FileNodeMenu
                file={file}
                selected={isSelected}
                startRenaming={() => setRenamingFileId(file.id)}
                href={href}
              />
            </div>
          </ContextMenu.Trigger>
        </Link>
        <ContextMenu.Portal>
          <FileNodeMenuItems
            file={file}
            href={href}
            selected={isSelected}
            menuType="context"
            startRenaming={() => setRenamingFileId(file.id)}
          />
        </ContextMenu.Portal>
      </ContextMenu.Root>

      {/* TODO: likely move this to FileSystemModals */}
      {renameDialog.open ? (
        <EventPropagationShield>
          <FileRenameModal
            file={file}
            initialName={renameDialog.name}
            open={renameDialog.open}
            onRename={(file) => {
              onChange([
                {
                  type: "update",
                  item: {
                    type: "file",
                    item: directoryFileFromFile(file),
                  },
                },
              ]);
            }}
            onClose={() => {
              setIsHandlingRenaming(false);
              setRenameDialog({ name: file.name, open: false });
            }}
          />
        </EventPropagationShield>
      ) : null}
    </div>
  );
});
File.displayName = "File";

const EditableFileName = ({
  isEditing,
  stopEditing,
  handleRename,
  initialName,
  isHandlingRenaming,
}: {
  isEditing?: boolean;
  stopEditing: () => void;
  handleRename: (newName: string) => void;
  initialName: string;
  isHandlingRenaming: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [draft, setDraft] = useState(initialName);
  useEffect(() => {
    setDraft(initialName);
  }, [initialName]);

  // Focus and set cursor at the end when switching to edit mode
  // This focuses then immediately blurs! TO fix.
  useEffect(() => {
    if (isEditing) {
      // Delay the focus slightly to avoid immediate blur
      setTimeout(() => {
        const currentValue = inputRef.current?.value;
        if (currentValue) {
          inputRef.current?.setSelectionRange(currentValue.length, currentValue.length);
          inputRef.current?.focus();
        }
      }, 80); // A timeout delays the focus until after the current call stack has cleared
      // ... 0ms should be enough, but clearly there's something else that happens on the page.
      // Ok, I think its the tree nodes claiming focus if you've come from outside of an active tree
      // (like the home page) - have made it 80ms and i've also delayed setting the ID of the file to be
      // editted until after the page loaded.
    }
  }, [isEditing]);

  const attemptRename = (newName: string) => {
    if (!newName.trim()) {
      setDraft(initialName);
    } else if (newName.trim() !== initialName) {
      handleRename(newName);
    }
    stopEditing();
  };

  return (
    <>
      <div
        className={classNames(
          "truncate whitespace-pre pl-4",
          isEditing ? "hidden" : "",
          isHandlingRenaming ? "animate-pulse opacity-80" : "",
        )}
      >
        {initialName}
      </div>
      <input
        maxLength={128}
        onKeyDown={(event) => {
          // This is to stop the keyboard from navigation in the tree (which can't be turned off)
          event.stopPropagation();
          if (event.key === "Enter") {
            // I think this was causing forms to submit... so preventDefault
            event.preventDefault();
            attemptRename(draft);
          }
          if (event.key === "Escape") {
            setDraft(initialName);
            stopEditing();
          }
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        onBlur={() => {
          attemptRename(draft);
        }}
        className={classNames(
          isEditing
            ? "w-full rounded-ms border border-stroke-secondary-3 bg-white px-[3px] py-2 focus:outline-none"
            : "hidden",
        )}
        onChange={(e) => setDraft(e.target.value)}
        ref={inputRef}
        value={draft}
      />
    </>
  );
};

type NodeType = "directory" | "file";

type BaseNodeData<N extends NodeType> = {
  id: string;
  nodeType: N;
};

type FileTreeNode = BaseNodeData<"file"> & DirectoryFile;
type DirectoryTreeNode = BaseNodeData<"directory"> & DirectoryType & { children: (DirectoryTreeNode | FileTreeNode)[] };

/** New file button */
type NewFileNode = { id: string; nodeType: "new-file"; name: "new-file" };

export type TreeNodeData = DirectoryTreeNode | FileTreeNode | NewFileNode;

export const FILE_PAGES_HIDDEN_FROM_TREE: FilePage[] = ["settings"];

export const useDirectoryTree = ({ selectedNode }: { selectedNode?: string }) => {
  const { structure, ...rest } = useDirectoryStructure();

  const tree: TreeNodeData[] | undefined = useMemo(() => {
    if (!structure) return undefined;
    const directories: DirectoryTreeNode[] = [...structure.directories]
      .map(
        (directory) =>
          ({
            nodeType: "directory",
            ...directory,
            children: [],
          }) satisfies DirectoryTreeNode,
      )
      .sort((a, b) => a.name.localeCompare(b.name));
    const files: FileTreeNode[] = [...structure.files]
      .map(
        (file) =>
          ({
            nodeType: "file",
            ...file,
          }) satisfies FileTreeNode,
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    const nodes: TreeNodeData[] = [
      ...convertToTree({ directories, files })[0].children,
      { id: "new-file", nodeType: "new-file", name: "new-file" },
    ];
    return nodes;
  }, [structure]);

  return { tree, ...rest };
};

/**
 * Returns the FileOrDirectory type from a TreeNodeData node.
 */
export const getFileOrDirectoryFromNode = (node: NodeApi<TreeNodeData>): FileOrDirectory | null => {
  const data = node.data;

  if (data.nodeType === "directory") {
    return {
      type: "directory",
      item: data,
    };
  } else if (data.nodeType === "file") {
    return {
      type: "file",
      item: data,
    };
  }

  // Skip "new-file" nodes or any other types
  return null;
};

export const getRootDirectory = (directories: DirectoryType[]) => {
  return directories.find((directory) => directory.parent_id === null);
};

const convertToTree = (data: { directories: DirectoryTreeNode[]; files: FileTreeNode[] }): DirectoryTreeNode[] => {
  // Create a map of directories with their children
  const directoryMap: { [key: string]: DirectoryTreeNode } = {};
  data.directories.forEach((directory) => {
    directoryMap[directory.id] = { ...directory, children: [] };
  });

  // Add directories and files to their parent directory's children
  data.directories.forEach((directory) => {
    if (directory.parent_id) {
      const parent = directoryMap[directory.parent_id];
      if (parent === undefined) {
        console.error(`Failed to find parent directory for directory`, { directory });
        return;
      }
      parent.children.push(directoryMap[directory.id]);
    }
  });
  data.files.forEach((file) => {
    if (file.directory_id) {
      const directory = directoryMap[file.directory_id];
      if (directory === undefined) {
        console.error(`Failed to find directory for file`, { file });
        return;
      }
      directory.children.push(file);
    }
  });

  // Return the root directories (those without a parent)
  return Object.values(directoryMap).filter((directory) => directory.parent_id === null);
};

export const NodeRenderer = React.memo(({ node, tree, dragHandle, ...props }: NodeRendererProps<TreeNodeData>) => {
  // Open Directory when selected. But don't open it if manually closed.
  const previousSelected = usePrevious(node.state.isSelected);
  useEffect(() => {
    if (node.data.nodeType === "directory" && node.state.isSelected && !previousSelected && node.isClosed) {
      node.open();
    }
  }, [node, previousSelected]);

  // This is needed otherwise it will look like a directory can be dropped into a grandchild directory.
  // Though this is not the biggest deal, as we prevent anything from happening in the drop handler.
  const preventDrop =
    tree.dragNodes.length > 0 ? shouldPreventDrop({ parentNode: node, dragNodes: tree.dragNodes }) : false;

  let element = null;
  if (node.data.nodeType === "directory") {
    element = (
      <Directory
        dragHandle={dragHandle}
        directory={node.data}
        onToggle={() => node.toggle()}
        indentLevel={node.level}
        isSelected={node.state.isSelected}
        isOpen={node.state.isOpen}
        // This additional prevent check is a hack, because for whatever reason willReceive drop seems to
        // otherwise allow it to appear like a directory can be dropped into itself or its direct parent.
        willReceiveDrop={node.state.willReceiveDrop && !preventDrop}
        isDragging={node.state.isDragging}
      />
    );
  } else if (node.data.nodeType === "file") {
    element = (
      <File
        dragHandle={dragHandle}
        file={node.data}
        indentLevel={node.level}
        isSelected={node.state.isSelected}
        isDragging={node.state.isDragging}
      />
    );
  } else if (node.data.nodeType === "new-file") {
    element = <NewFileNodeItem />;
  } else {
    // @ts-expect-error - Ensures that all `node.data.nodeType`s are handled (and so has type "never" here)
    throw new Error(`Invalid node type: ${node.data.nodeType}`);
  }
  return <div className="select-none truncate">{element}</div>;
});
NodeRenderer.displayName = "NodeRenderer";

/** The "+ New button" for adding a new file or directory */
export const NewFileNodeItem = () => {
  const { setFileModalState } = useSidebarContext();
  return (
    <>
      <div className="mx-[7px] flex flex-col ">
        <NavItem
          Icon={PlusIcon}
          title="New"
          onClick={() => setFileModalState({ open: true, type: "create-file" })}
          className={"pl-10"}
        />
      </div>
    </>
  );
};
