import { classNames } from "@/lib/utils";
import { DirectoryFile, directoryFileFromFile } from "@/services/directories.service";
import { capitalizedFileType, renameFile, shouldWarnOnPathChange } from "@/services/files.service";
import { File } from "@/types/app/file";
import Alert, { AlertVariant } from "@components/library/Alert";
import { RadixAppDialog } from "@components/library/AppDialog";
import Button from "@components/library/Button";
import { FormikTextInput } from "@components/molecules/FormikInput";
import { CheckIcon } from "@heroicons/react/outline";
import { Form, Formik } from "formik";
import React, { useEffect, useId } from "react";

interface OwnProps {
  onRename: (file: File) => void;
  file: DirectoryFile;

  // These are so we can re-use of the logic in this modal, but not show the modal in the
  // simple cases where the user edits inline.
  initialName?: string;
}

type Props = OwnProps & Pick<React.ComponentPropsWithoutRef<typeof RadixAppDialog>, "open" | "onClose">;

/*
 * Modal for renaming a file that acts as a warning too.
 */
export const FileRenameModal = ({ onRename, file, open, onClose, initialName }: Props) => {
  // Focus on input on open
  // Required as hack around Radix bug where focus is grabbed by close button.
  // (Seemingly only needed when opening from table, but not from sidebar. Strange.)
  // See: https://github.com/radix-ui/primitives/issues/1615
  const id = useId();
  useEffect(() => {
    if (open) {
      const input = document.getElementById(id);
      if (input) {
        const timeout = setTimeout(() => {
          input?.focus();
        }, 10);
        return () => clearTimeout(timeout);
      }
    }
  }, [id, open]);

  const fileType = capitalizedFileType(file.type);

  const shouldWarn = shouldWarnOnPathChange({
    type: "file",
    item: file,
  });

  return (
    <RadixAppDialog open={open} onClose={onClose} title={`Rename ${fileType}`} className={classNames("max-w-lg")}>
      <div className="flex flex-col gap-32">
        {shouldWarn ? (
          <Alert
            size="sm"
            title={`Renaming the ${fileType} can break your API calls`}
            description={shouldWarn.warningMessage}
            variant={AlertVariant.Error}
          />
        ) : null}

        <Formik
          initialValues={{
            name: initialName || file.name,
          }}
          onSubmit={(values: any, { setSubmitting, setErrors }) => {
            if (values.name === file.name) {
              onClose?.();
              return;
            }
            renameFile({
              file,
              name: values.name,
              onSuccess: (file) => {
                onRename(file);
                onClose?.();
              },
              onError: (error) => {
                if (error.response?.status === 409) {
                  setErrors({ name: "Name is already taken" });
                }
              },
            });
          }}
        >
          <Form className="flex flex-col gap-32">
            <FormikTextInput
              autoFocus // This autoFocus works for grabbing focus when opening from the sidebar.
              id={id}
              name="name"
              label="Name"
              placeholder={file.name}
            />
            <div className="flex justify-between">
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit" IconLeft={CheckIcon} styling="solid" shade="black">
                Rename
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </RadixAppDialog>
  );
};
