import { classNames } from "@/lib/utils";
import { LoadingIcon } from "@components/library/Icons";
import { CaretRight } from "@phosphor-icons/react";
import React from "react";

interface OptionCardProps {
  badge: React.ReactNode;
  title: string;
  description: string;
  onClick?: () => void;
  rightIcon?: React.ReactNode;
  creating?: boolean;
  tag?: React.ReactNode;
  disabled?: boolean;
}

export const OptionCard = ({
  badge,
  title,
  description,
  onClick,
  rightIcon,
  creating,
  tag,
  disabled,
}: OptionCardProps) => {
  return (
    <div
      className={classNames(
        "flex w-full min-w-0 items-center gap-4 rounded-ms py-[22px] pl-16 pr-8 shadow-card-sm hover:shadow-card-md",
        "cursor-pointer",
      )}
      onClick={disabled ? () => {} : onClick}
    >
      <div className="flex w-full min-w-0 items-center gap-12">
        <div className="text- flex h-20 w-20 items-center justify-center rounded-ms">{badge}</div>
        <div className="flex min-w-0 max-w-full flex-col gap-8">
          <div className="text-14-14 font-medium text-text-base-1">{title}</div>
          <div className="truncate pb-1 text-12-12 text-text-base-3">{description}</div>
        </div>
      </div>
      <div className="flex items-center gap-12">
        {tag && <div className="select-none">{tag}</div>}
        {rightIcon ? (
          <CaretRight className="m-8 h-20 w-20 text-icon-base-3" />
        ) : creating ? (
          <LoadingIcon className="m-8 h-20 w-20" />
        ) : (
          <div className="bg-red m-8 h-20 w-20" />
        )}
      </div>
    </div>
  );
};
