export function sample<Type>(choices: Type[]): Type {
  return choices[Math.floor(Math.random() * choices.length)];
}

// Taken from https://stackoverflow.com/a/53758827
export const shuffleWithSeed = (array: any[], seed: number): any[] => {
  let m = array.length;
  let t, i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(random(seed) * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed;
  }

  return array;
};

/** Returns a random number between 0 and 1. */
function random(seed: number): number {
  seed = (seed * 9301 + 49297) % 233280;
  return seed / 233280;
}

// Taken from https://stackoverflow.com/a/66902484
export function linspace(start: number, stop: number, num: number, endpoint = true) {
  const div = endpoint ? num - 1 : num;
  const step = (stop - start) / div;
  return Array.from({ length: num }, (_, i) => start + step * i);
}

/** Hash function (DJB2 algorithm)
 *
 * Useful for generating a seed from a string
 */
export function hashStringToNumber(str: string): number {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    hash = (hash * 33) ^ str.charCodeAt(i);
  }
  return hash >>> 0; // Ensure positive integer
}

export function randomFromString(seed: string): number {
  const hash = hashStringToNumber(seed);
  return random(hash);
}
