import { ChatMessage } from "@/services/playground.service";
import { Prompt, PromptKernelRequest, PromptResponse } from "./prompt";
import { Tool, ToolKernelRequest, ToolResponse } from "./tool";
import { Evaluator, EvaluatorResponse, EvaluatorSpec } from "./evaluator";
import { parseFileResponse } from "./file";
import { Dayjs } from "dayjs";
import { parseTimestamp } from "@/services/utils";
import { Flow, FlowResponse, parseFlowResponse } from "./flow";
import { EvaluationDatapoint, EvaluationRun } from "@/services/evaluationReports.service";
import { getLatestEvaluatorLog } from "@/services/evaluators.service";
import { boolean } from "yup";

export interface ToolChoice {
  type: string;
  function: Record<string, any>;
}

export type ToolChoiceOptions = "none" | "auto" | "required" | ToolChoice | null;

export type ObservabilityStatus = "pending" | "running" | "completed" | "failed";

interface BaseDatumRequest {
  session_id: string | null;
  parent_id: string | null;
  inputs: Record<string, any> | null;
  source: string | null;
  metadata: Record<string, any> | null;
  save: boolean;
  source_datapoint_id: string | null;
  batches: string[] | null;
  user: string | null;
  environment: string | null;
  start_time: string | null;
  end_time: string | null;
}

interface BaseLogRequestInput {
  provider_request: Record<string, any> | null;
  provider_response: Record<string, any> | null;
}

interface BaseLogRequestOutput {
  output: string | null;
  raw_output: string | null;
  created_at: string | null;
  error: string | null;
  stdout: string | null;
  provider_latency: number | null;
}

interface LogRequest extends BaseDatumRequest, BaseLogRequestInput, BaseLogRequestOutput {}

interface BaseLogResponse extends LogRequest {
  id: string;
  observability_status: ObservabilityStatus;
  updated_at: string;
  evaluator_logs: EvaluatorLogResponse[];
  trace_id: string | null;
  trace_flow_id: string | null;
  trace_parent_id: string | null;
}

// For v5 we have a different response type for creating logs compared to the actual log response.
// Ideally this would be updated to be consistent.
export interface CreateLogResponse {
  id: string;
  parent_id: string;
  session_id: string | null;
  version_id: string;
}

export type FilterOperator = "eq" | "ne" | "co" | "nc" | "gt" | "lt" | "er" | "ok";

export const filterOperatorCodeToName: Record<FilterOperator, string> = {
  eq: "equals",
  ne: "not equals",
  co: "contains",
  nc: "not contains",
  gt: "greater than",
  lt: "less than",
  er: "error",
  ok: "no error",
};

export interface ReviewViewFilter {
  id?: string;
  entity: "datapoint" | "evaluator";
  property_to_filter: string;
  operator: FilterOperator;
  value: any;
}

interface BasePromptLogInput {
  messages: ChatMessage[] | null;
  tool_choice: ToolChoiceOptions;
}

interface PromptLogInput extends BasePromptLogInput {
  prompt: PromptKernelRequest | null;
}

interface BasePromptLogOutput {
  output_message: ChatMessage | null;
  prompt_tokens: number | null;
  output_tokens: number | null;
  prompt_cost: number | null;
  output_cost: number | null;
  finish_reason: string | null;
}

// This is PromptRequestIdentifiers, ToolRequestIdentifiers, etc. in the backend.
// While they're separate classes in the backend as we want to have specific documentation strings,
// we don't have that requirement here.
export interface FileRequestIdentifiers {
  path?: string | null;
  id?: string | null;
  directory_id?: string | null;
}

interface PromptLogRequest extends LogRequest, PromptLogInput, BasePromptLogOutput, FileRequestIdentifiers {}

export interface PromptLogResponse extends BaseLogResponse, Omit<PromptLogRequest, "path" | "id" | "prompt"> {
  prompt: PromptResponse;
  trace_children: LogResponse[];
}

interface ToolLogRequest extends LogRequest, FileRequestIdentifiers {
  tool: ToolKernelRequest;
  trace_children: LogResponse[];
}

export interface ToolLogResponse extends BaseLogResponse, Omit<ToolLogRequest, "id" | "path" | "tool"> {
  tool: ToolResponse;
  trace_children: LogResponse[];
}

interface EvaluatorLogRequest {
  judgment: any;
  marked_completed?: boolean;
}

export interface CreateEvaluatorLogRequest extends EvaluatorLogRequest, FileRequestIdentifiers {
  parent_id: string;
  spec?: EvaluatorSpec;
  user?: string;
}

export interface EvaluatorLogResponse
  extends BaseLogResponse,
    Omit<CreateEvaluatorLogRequest, "id" | "path" | "parent_id" | "user"> {
  evaluator: EvaluatorResponse;
  parent: LogResponse | null;
  // parent_id can be null in the response, but it's required in the request.
  // We have some legacy logs that don't have a parent_id
  parent_id: string | null;
  trace_children: LogResponse[];
}

export interface FlowLogResponse extends BaseLogResponse, LogRequest {
  flow: FlowResponse;
  trace_children: LogResponse[];
  trace_status: "complete" | "incomplete" | null;
}

export type LogResponse = PromptLogResponse | ToolLogResponse | EvaluatorLogResponse | FlowLogResponse;

type LogFromResponse<T> = Omit<
  T,
  | "prompt"
  | "tool"
  | "evaluator"
  | "flow"
  | "created_at"
  | "updated_at"
  | "evaluator_logs"
  | "parent"
  | "start_time"
  | "end_time"
  | "trace_children"
> & {
  created_at: Dayjs;
  updated_at: Dayjs;
  start_time: Dayjs | null;
  end_time: Dayjs | null;
  // We can't add this here as it would create a circular dependency.
  // We have to add it individually to each Log type below.
  // evaluator_logs: EvaluatorLog[];
};

export type PromptLog = LogFromResponse<PromptLogResponse> & { prompt: Prompt } & { evaluator_logs: EvaluatorLog[] } & {
  trace_children: Log[];
};
export type ToolLog = LogFromResponse<ToolLogResponse> & { tool: Tool } & { evaluator_logs: EvaluatorLog[] } & {
  trace_children: Log[];
};
export type EvaluatorLog = LogFromResponse<EvaluatorLogResponse> & { evaluator: Evaluator } & {
  evaluator_logs: EvaluatorLog[];
  parent: Log | null;
} & { trace_children: Log[] };
export type FlowLog = LogFromResponse<FlowLogResponse> & { flow: Flow; evaluator_logs: EvaluatorLog[] } & {
  trace_children: Log[];
};

export type Log = PromptLog | ToolLog | EvaluatorLog | FlowLog;

export const parseLogResponse = (response: LogResponse): Log => {
  if (!response.created_at) {
    console.log("Log response is missing created_at", response);
    throw new Error(`Log response is missing created_at: '${response.id}'`);
  }
  const parsedTimestamps = {
    created_at: parseTimestamp(response.created_at),
    updated_at: parseTimestamp(response.updated_at),
    start_time: response.start_time !== null ? parseTimestamp(response.start_time) : null,
    end_time: response.end_time !== null ? parseTimestamp(response.end_time) : null,
  };
  const parsedEvaluatorLogs: EvaluatorLog[] = response.evaluator_logs.map(parseLogResponse) as EvaluatorLog[];

  if ("prompt" in response) {
    return {
      ...response,
      ...parsedTimestamps,
      prompt: parseFileResponse(response.prompt),
      evaluator_logs: parsedEvaluatorLogs,
      trace_children: response.trace_children.map(parseLogResponse),
    };
  } else if ("tool" in response) {
    return {
      ...response,
      ...parsedTimestamps,
      tool: parseFileResponse(response.tool),
      evaluator_logs: parsedEvaluatorLogs,
      trace_children: response.trace_children.map(parseLogResponse),
    };
  } else if ("evaluator" in response) {
    return {
      ...response,
      ...parsedTimestamps,
      evaluator: parseFileResponse(response.evaluator),
      evaluator_logs: parsedEvaluatorLogs,
      parent: response.parent ? (parseLogResponse(response.parent) as Log) : null,
      trace_children: response.trace_children.map(parseLogResponse),
    };
  } else if ("flow" in response) {
    return {
      ...response,
      ...parsedTimestamps,
      flow: parseFlowResponse(response.flow),
      evaluator_logs: parsedEvaluatorLogs,
      trace_children: response.trace_children.map(parseLogResponse),
    };
  }
  let x: never = response;
  throw new Error(`Unhandled response type: ${x}`);
};

export const getFileFromLog = (log: Log): Prompt | Tool | Evaluator | Flow => {
  if ("prompt" in log) {
    return log.prompt;
  } else if ("tool" in log) {
    return log.tool;
  } else if ("evaluator" in log) {
    return log.evaluator;
  } else if ("flow" in log) {
    return log.flow;
  }
  let x: never = log;
  throw new Error(`Unhandled log type: ${x}`);
};

/** Get the Prompt from a Log.
 *
 * Retrieves the Prompt from a Prompt Log, or the Prompt from an AI Evaluator Log.
 * (Cannot handle linked tools here as the types are different.)
 */
export const getPromptFromLog = (log: Log): Omit<PromptKernelRequest, "linked_tools"> | undefined => {
  if ("prompt" in log) {
    return log.prompt;
  } else if ("evaluator" in log && "prompt" in log.evaluator.spec) {
    return log.evaluator.spec.prompt;
  }
  return undefined;
};

/**
 * Extracts the "reasoning" argument from a log's output message if it exists in a tool call's arguments.
 *
 * For our Evaluator Logs.
 * This looks for a single tool call in the log's output_message and checks if its
 * arguments contain a "reasoning" field. If found, returns that reasoning as a string.
 */
export const getReasoningFromLog = (log: any): string | null => {
  if (
    "output_message" in log &&
    log.output_message &&
    log.output_message.tool_calls &&
    log.output_message.tool_calls.length === 1
  ) {
    const toolCall = log.output_message.tool_calls[0];
    try {
      const parsedArguments = JSON.parse(toolCall.function.arguments);
      if ("reasoning" in parsedArguments) {
        return parsedArguments.reasoning;
      }
    } catch (e) {
      // Do nothing
    }
  }
  return null;
};

export const getLogMatchingRun = (datapoint: EvaluationDatapoint, run: EvaluationRun): Log | null => {
  //  Loop through datapoint logs and find log that corresponds to the given evaluatee
  const logsMatchingEvaluatee = datapoint.logs.filter((log) => log.run_id === run.id);
  if (logsMatchingEvaluatee.length > 1) {
    console.error(`Expected to find one match, found ${logsMatchingEvaluatee.length}`, datapoint, run);
    return null;
  }
  return logsMatchingEvaluatee.length > 0 ? logsMatchingEvaluatee[0].log : null;
};

// Note: we filter logs on the backend; however, we return all logs for the datapoint if at least one log matches the filter.
// We believe this is better for UX (not showing logs makes the table look broken).
// However, we want to fade logs that don't match the filter (but still show them).
// This leads to complexity as we need to have the exact same logic on the backend and frontend to ensure we match the same logs.
// Especially tricky as the numbers might have different precision, etc.
// Maybe there is a better way!
export const logSatisfiesFilters = (log: Log, filters: ReviewViewFilter[]): boolean => {
  const judgmentFilters = filters.filter((filter) => filter.entity === "evaluator");
  if (judgmentFilters.length === 0) {
    return true;
  }

  const evaluator_version_id_to_filter: Record<string, ReviewViewFilter> = Object.fromEntries(
    judgmentFilters.map((filter) => [filter.property_to_filter, filter]),
  );

  return Object.entries(evaluator_version_id_to_filter).every(([version_id, filter]) => {
    const evaluatorLogs =
      log?.evaluator_logs?.filter((evaluatorLog) => evaluatorLog.evaluator.version_id === version_id) || [];

    const lastEvaluatorLog = getLatestEvaluatorLog(evaluatorLogs);

    // Handle cases where there's no evaluator log
    if (!lastEvaluatorLog) {
      return filter.operator === "nc" || filter.operator === "ne";
    }

    const judgment = lastEvaluatorLog.judgment;
    const value = filter.value;
    const returnType = lastEvaluatorLog.evaluator.spec.return_type;

    // Convert values based on return type
    let convertedJudgment = judgment;
    let convertedValue = value;

    if (!["er", "ok"].includes(filter.operator)) {
      switch (returnType) {
        case "number":
          convertedJudgment =
            typeof judgment === "number" || typeof judgment === "string" ? parseFloat(judgment as string) : null;
          convertedValue = parseFloat(value);
          break;
        case "boolean":
          convertedJudgment = judgment !== null ? judgment.toString().toLowerCase() === "true" : null;
          convertedValue = value.toString().toLowerCase() === "true";
          break;
        default:
          convertedJudgment = judgment ? String(judgment) : null;
          convertedValue = String(value);
      }
    }

    // Handle comparison based on operator
    switch (filter.operator) {
      case "eq":
        return convertedJudgment === convertedValue;
      case "ne":
        return convertedJudgment !== convertedValue;
      case "co":
        return typeof convertedJudgment === "string" && convertedJudgment.includes(convertedValue);
      case "nc":
        return typeof convertedJudgment !== "string" || !convertedJudgment.includes(convertedValue);
      case "gt":
        return (
          typeof convertedJudgment === "number" &&
          typeof convertedValue === "number" &&
          convertedJudgment > convertedValue
        );
      case "lt":
        return (
          typeof convertedJudgment === "number" &&
          typeof convertedValue === "number" &&
          convertedJudgment < convertedValue
        );
      case "er":
        return lastEvaluatorLog.error !== null;
      case "ok":
        return lastEvaluatorLog.error === null;
      default:
        return false;
    }
  });
};
