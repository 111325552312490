import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
} from "@radix-ui/react-dropdown-menu";
import IconButton from "@components/library/IconButton";
import { ChevronsDownUpIcon, ChevronsUpDownIcon } from "@components/library/Icons";
import { useAuth } from "@/lib/use-auth";
import { getUserOrganizations } from "@/lib/user";
import { useRouter } from "next/router";
import OrganizationIcon from "../Organizations/OrganizationIcon";
import { useState } from "react";
import { formatNumberSI, pluralise } from "@/lib/utils";
import { updateUser } from "@/services/users.service";
import { classNames } from "@components/library/utils/classNames";
import _ from "lodash";
import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { useSWRConfig } from "swr";

export const OrganizationSwitcher = () => {
  const { user, mutate: mutateUser } = useAuth();
  const organizations = getUserOrganizations(user);
  const { organization: activeOrganization, setActiveOrganization } = useActiveOrganization();

  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const { mutate } = useSWRConfig();

  if (organizations && organizations.length < 2) {
    return null;
  }

  return (
    <DropdownMenu open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DropdownMenuTrigger
        className={classNames(
          "flex h-full w-24 shrink-0 items-center justify-center rounded-ms hover:bg-gray-100 focus:bg-gray-100 focus:outline-none",
          isOpen && "bg-gray-100",
        )}
      >
        {isOpen ? (
          <ChevronsDownUpIcon className="h-16 w-16 text-gray-600" />
        ) : (
          <ChevronsUpDownIcon className="h-16 w-16 text-gray-600" />
        )}
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent className="relative top-10 z-[1000] w-[260px] animate-fade-in-down rounded-lg bg-white px-4 py-4 shadow-card-md will-change-[opacity,transform]">
          {organizations?.map(({ organization }) => {
            const isActiveOrganization = organization.slug === activeOrganization?.slug;
            return (
              <DropdownMenuItem
                key={organization.id}
                onClick={async () => {
                  if (isActiveOrganization) {
                    return;
                  }
                  await updateUser({ default_organization_id: organization.id });
                  setActiveOrganization &&
                    setActiveOrganization({
                      id: organization.id,
                      slug: organization.slug,
                    });
                  router.push(`/${organization.slug}`);

                  // Invalidate everything in SWR cache.
                  // See: https://swr.vercel.app/docs/advanced/cache#modify-the-cache-data
                  console.log("Clearing all cache");
                  mutate(
                    (key) => {
                      // Avoid mutating current user.
                      if (Array.isArray(key) && key[0].endsWith("/users/me")) {
                        return false;
                      }
                      return true;
                    }, // which cache keys are updated
                    undefined, // update cache data to `undefined`
                    { revalidate: true }, // refetch data after updating, needed for any pages that are currently open.
                  );
                  // Update the user object in the cache to reflect the new default organization
                  if (user) {
                    const userOrganizations = user?.organizations || [];
                    mutateUser({
                      ...user,
                      organizations: userOrganizations.map((org) => ({
                        ...org,
                        is_default_organization: org.organization.id === organization.id,
                      })),
                    });
                  }
                }}
                className={classNames(
                  "group/orgitem my-2 flex cursor-pointer justify-start rounded-ms bg-white outline-none",
                )}
              >
                <div
                  className={classNames(
                    "flex h-48 w-full max-w-[260px] justify-start gap-12 rounded-ms px-12 py-10 ",
                    isActiveOrganization
                      ? "bg-sky-50 group-hover/orgitem:bg-blue-100 group-active/orgitem:bg-[#E4EAFB]"
                      : "group-hover/orgitem:bg-gray-100 group-active/orgitem:bg-gray-150",
                  )}
                >
                  <OrganizationIcon organizationIcon={organization.icon} organizationId={organization.id} size={28} />
                  <div className="flex w-full min-w-0 flex-col items-start justify-start pb-4">
                    <div
                      className={classNames(
                        "flex w-full min-w-0 font-bold ",
                        isActiveOrganization ? "text-oldblue-900" : "text-gray-950",
                      )}
                    >
                      <span className="truncate pb-4 text-12-12">{organization.name}</span>
                    </div>
                    <div
                      className={classNames(
                        "flex w-full min-w-0 items-center text-11-12 font-normal",
                        isActiveOrganization
                          ? "text-oldblue-900"
                          : "text-gray-600 group-hover/orgitem:text-gray-700 group-active/orgitem:text-gray-700",
                      )}
                    >
                      <span className="truncate">
                        {formatNumberSI(organization.members)} member{pluralise(organization.members)}
                      </span>
                      <div
                        className={classNames(
                          "mx-4 h-2 w-2 shrink-0 rounded-full",
                          isActiveOrganization
                            ? "bg-oldblue-900"
                            : "bg-gray-600 group-hover/orgitem:bg-gray-700 group-active/orgitem:bg-gray-700",
                        )}
                      />
                      <span className="truncate">
                        {organization.active_subscription && (
                          <> {_.startCase(organization.active_subscription?.product.name)}</>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
};
