// This is the main app layout. It contains the sidebar and the main content area.
import { SidebarContextProvider, useSidebarContext } from "@/context/SidebarContextProvider";
import DeviceSupport from "@components/atoms/DeviceSupport";
import { useCallback, useRef, useState } from "react";
import {
  ImperativePanelGroupHandle,
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import { useIsMounted, useWindowSize } from "usehooks-ts";
import { FilesystemModals } from "./Sidebar/FilesystemModals";
import { Sidebar } from "./Sidebar/Sidebar";

const DEFAULT_SIDEBAR_SIZE = 224;
const MIN_SIDEBAR_SIZE = 218;
const MAX_SIDEBAR_SIZE = 330;

// SidebarLayout which sits inside SidebarContextProvider
const SidebarLayoutInner = ({ children }: React.PropsWithChildren) => {
  const panelGroupRef = useRef<ImperativePanelGroupHandle>(null);
  const sidebarPanelRef = useRef<ImperativePanelHandle>(null);

  const { sidebarCollapsed, _setSidebarCollapsed, toggleSidebarRef, sidePanelOpen, setSidePanelOpen } =
    useSidebarContext();

  const { width } = useWindowSize({ debounceDelay: 1000 });
  const resetLayout = useCallback(() => {
    const panelGroup = panelGroupRef.current;
    if (panelGroup) {
      panelGroup.setLayout([DEFAULT_SIDEBAR_SIZE, width - DEFAULT_SIDEBAR_SIZE], "pixels");
    }
  }, [width]);

  const [animating, setAnimating] = useState(false);
  const isMounted = useIsMounted();

  // This sidebar toggling seems overly complex... and we've had users have issues
  //  where its fixed open  full width etc. In the future, I expect we should just
  //  use a less janky component and do it again.
  const onToggleSidebar = useCallback(() => {
    setAnimating(true);
    requestAnimationFrame(() => {
      if (sidebarCollapsed) {
        sidebarPanelRef.current?.expand();
        requestAnimationFrame(() => {
          const sidebarSize = sidebarPanelRef.current?.getSize();
          if (sidebarSize !== undefined && sidebarSize === 0) {
            console.log("Detected sidebar might've been stuck collapsed. Manually expanding...");
            sidebarPanelRef.current?.resize(DEFAULT_SIDEBAR_SIZE, "pixels");
          }
        });
      } else {
        sidebarPanelRef.current?.collapse();
        requestAnimationFrame(() => {
          const sidebarSize = sidebarPanelRef.current?.getSize();
          if (sidebarSize !== undefined && sidebarSize > 0) {
            console.log("Detected sidebar might've been stuck expanded. Manually collapsing...");
            panelGroupRef.current?.setLayout([0, 100], "percentages");
          }
        });
      }
      // Note that this setSidebarCollapse can't be passed a callback ((collapsed) => !collapsed)
      // as it seems to cause issues that present as the sidebar expand icon button appearing
      // in the app header even with the sidebar expanded.
      _setSidebarCollapsed(!sidebarCollapsed);
    });
    setTimeout(() => {
      if (isMounted()) {
        setAnimating(false);
      }
    }, 300);
  }, [isMounted, sidebarCollapsed, _setSidebarCollapsed]);

  toggleSidebarRef.current = onToggleSidebar;

  return (
    <div className="flex h-screen">
      <DeviceSupport />
      <PanelGroup direction="horizontal" units="pixels" autoSaveId="hl-sidebar" ref={panelGroupRef}>
        <Panel
          ref={sidebarPanelRef}
          defaultSize={DEFAULT_SIDEBAR_SIZE}
          minSize={MIN_SIDEBAR_SIZE}
          maxSize={MAX_SIDEBAR_SIZE}
          collapsible
          collapsedSize={0}
          onCollapse={_setSidebarCollapsed}
          className={animating ? "duration-150" : ""}
        >
          <Sidebar onToggleSidebar={onToggleSidebar} />
        </Panel>

        <div onDoubleClick={resetLayout} className="relative h-full w-0 border-r border-stroke-base-2">
          {/* z-index of 1 so the resize handle appears on top of right panel */}
          <PanelResizeHandle className="absolute -left-2 -right-2 bottom-0 top-0 z-[1] duration-75 hover:bg-stroke-base-3 active:bg-stroke-base-4" />
        </div>

        <Panel>
          <div className="relative flex h-full flex-col @container">{children}</div>
        </Panel>
      </PanelGroup>

      <FilesystemModals />
    </div>
  );
};

export const SidebarLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <SidebarContextProvider>
      <SidebarLayoutInner>{children}</SidebarLayoutInner>
    </SidebarContextProvider>
  );
};
