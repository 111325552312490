import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { useSidebarContext } from "@/context/SidebarContextProvider";
import { fileHasPage, getDirectoryHref, getFileHref } from "@/lib/path-utils";
import { DirectoryFile, FileOrDirectory } from "@/services/directories.service";
import { FileCreateModal } from "@components/llama/Files/FileCreateModal";
import { DirectoryModal } from "@components/llama/Projects/DirectoryModal";
import { MoveDirectoryModal } from "@components/llama/Projects/MoveDirectoryModal";
import { useRouter } from "next/router";

/** Modal for file/directory creation, renaming, and moving. */
export const FilesystemModals = () => {
  const router = useRouter();
  const { slug } = useActiveOrganization();
  const { rootDirectory, fileModalState, setFileModalState, directoryStructureOnChange, setRenamingFileId } =
    useSidebarContext();

  return (
    <>
      {fileModalState.type === "create-file" && (
        <FileCreateModal
          open={fileModalState.open}
          onClose={() => setFileModalState((state) => ({ ...state, open: false }))}
          onCreate={async (file: DirectoryFile) => {
            directoryStructureOnChange([
              {
                type: "create",
                item: { type: "file", item: file },
              },
            ]);
            setFileModalState((state) => ({ open: false, type: "create-file" }));
            if (slug === null) {
              console.error("Cannot navigate to file as `slug` is null");
              return;
            }
            // Await so that we set the file ID afterwards, otherwise it goes straight to editing the
            //  name, but that breaks when the page loads as the tree steals focus.
            await router.push({
              pathname: getFileHref({
                slug,
                id: file.id,
                page: fileHasPage(file, "editor") ? "editor" : "dashboard",
              }),
              query: file.type === "flow" ? { showTutorial: true } : undefined,
            });
            setRenamingFileId(file.id);
          }}
          directory={fileModalState.targetDirectory || rootDirectory}
          onCreateDirectory={() => {
            setFileModalState((state) => ({ ...state, open: true, type: "create-directory" }));
          }}
        />
      )}

      {fileModalState.type === "create-directory" && rootDirectory && (
        <DirectoryModal
          open={fileModalState.open}
          onClose={() => setFileModalState((state) => ({ ...state, open: false }))}
          onCreate={(directory) => {
            directoryStructureOnChange([
              {
                type: "create",
                item: { type: "directory", item: directory },
              },
            ]);
            if (slug === null) {
              console.error("Cannot navigate to file as `slug` is null");
              return;
            }
            router.push(getDirectoryHref({ slug, id: directory.id }));
          }}
          parent={fileModalState.targetDirectory || rootDirectory}
          mode="create"
        />
      )}

      {fileModalState.type === "rename-directory" && fileModalState.directory && (
        <DirectoryModal
          open={fileModalState.open}
          onClose={() => setFileModalState((state) => ({ ...state, open: false }))}
          onRename={(newDirectory) => {
            directoryStructureOnChange([
              {
                type: "update",
                item: { type: "directory", item: newDirectory },
              },
            ]);
          }}
          directory={fileModalState.directory}
          mode="rename"
        />
      )}

      {fileModalState.type === "move" && (
        <MoveDirectoryModal
          open={fileModalState.open}
          onClose={() => setFileModalState((state) => ({ ...state, open: false }))}
          items={fileModalState.files}
          onMove={(move?: { items: FileOrDirectory[]; targetDirectoryId: string }) => {
            if (!move) return;
            directoryStructureOnChange(
              move?.items.map((item) => ({
                type: "move",
                item,
                targetDirectoryId: move.targetDirectoryId,
              })),
            );
          }}
        />
      )}
    </>
  );
};
