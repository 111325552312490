import * as d3 from "d3";

interface LissajousProps {
  a: number;
  b: number;
  delay: number;
  length?: number;
  start?: number;
  size?: LissajousSize;
  strokeWidth?: number | string;
  className?: string;
}

export const Lissajous = ({
  a,
  b,
  delay,
  length = 500,
  start = 0,
  size = 24,
  strokeWidth,
  className,
}: LissajousProps) => {
  type Point = [number, number];
  type Points = Point[];
  const lineData: Points = [];

  const shapeWidth = size;
  strokeWidth = strokeWidth || Math.pow(size, 0.85) / 8; // visually guessing
  const canvasSize = size * 1.25;

  for (let i = start; i < length + start; i++) {
    lineData.push([
      // x
      (shapeWidth / 2) * Math.cos((a * 2 * Math.PI * i) / 180) + canvasSize / 2,
      // y
      (shapeWidth / 2) * Math.sin((b * 2 * Math.PI * i - Math.PI * delay) / 180) + canvasSize / 2,
    ]);
  }

  const lineFunction = d3
    .line()
    //Interpolation. I have just chosen the first one i found.
    .curve(d3.curveCatmullRom.alpha(0.2));

  return (
    <svg width={size} height={size} viewBox={`0 0 ${canvasSize} ${canvasSize}`} className={className}>
      <path d={lineFunction(lineData) as string} stroke={"currentColor"} fill={"none"} strokeWidth={strokeWidth} />
    </svg>
  );
};

export type LissajousSize = 16 | 20 | 24 | 32 | 40 | 64;

type NumberedLissajousProps = { delay?: number; size?: LissajousSize } & Omit<
  LissajousProps,
  "a" | "b" | "delay" | "size"
>;

export const Lissajous1 = ({ delay = 0, size = 24, ...props }: NumberedLissajousProps) => (
  <Lissajous a={1} b={1} delay={delay} size={size} {...props} />
);

export const Lissajous2 = ({ delay = 0, size = 24, ...props }: NumberedLissajousProps) => (
  <Lissajous a={1} b={2} delay={delay} size={size} {...props} />
);

export const Lissajous3 = ({ delay = 0, size = 24, ...props }: NumberedLissajousProps) => (
  <Lissajous a={1} b={3} delay={delay} size={size} {...props} />
);

export const Lissajous4 = ({ delay = 0, size = 24, ...props }: NumberedLissajousProps) => (
  <Lissajous a={3} b={2} delay={delay} size={size} {...props} />
);

export const Lissajous5 = ({ delay = 0, size = 24, ...props }: NumberedLissajousProps) => (
  <Lissajous a={3} b={4} delay={delay} size={size} {...props} />
);

type PredefinedLissajousProps = Omit<NumberedLissajousProps, "delay">;

// Predefined Lissajous for use as product icons
export const LissajousAnnotate = (props: PredefinedLissajousProps) => <Lissajous1 delay={0} {...props} />;

export const LissajousDeploy = (props: PredefinedLissajousProps) => (
  // Just off 90 so that the line has rounded end caps
  <Lissajous2 delay={-90.1} {...props} />
);

export const LissajousImprove = (props: PredefinedLissajousProps) => <Lissajous3 delay={90.1} {...props} />;

// Annotate
export const LissajousProgrammatic = (props: PredefinedLissajousProps) => <Lissajous1 delay={90.1} {...props} />;

export const LissajousAnnotationTeams = (props: Omit<NumberedLissajousProps, "delay">) => (
  <Lissajous1 delay={45} {...props} />
);

export const LissajousActiveLearning = (props: PredefinedLissajousProps) => <Lissajous4 delay={-90.1} {...props} />;

// Deploy
export const LissajousHITL = (props: PredefinedLissajousProps) => <Lissajous2 delay={0} {...props} />;

export const LissajousAutoTrain = (props: PredefinedLissajousProps) => <Lissajous2 delay={-30} {...props} />;

export const LissajousInference = (props: PredefinedLissajousProps) => <Lissajous2 delay={30} {...props} />;

// Improve
export const LissajousActiveTesting = (props: PredefinedLissajousProps) => <Lissajous4 delay={-30.1} {...props} />;

export const LissajousDataDebugger = (props: PredefinedLissajousProps) => <Lissajous3 delay={-89.9} {...props} />;

export const LissajousMonitoring = (props: PredefinedLissajousProps) => <Lissajous4 delay={0} {...props} />;
