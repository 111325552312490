import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { useSidebarContext } from "@/context/SidebarContextProvider";
import useConfirm from "@/context/useConfirm";
import { filePagesForFileType, useAppHref } from "@/lib/path-utils";
import { classNames } from "@/lib/utils";
import {
  Directory,
  DirectoryFile,
  deleteDirectory,
  isDirectoryFile,
  useDirectory,
  useDirectoryStructure,
} from "@/services/directories.service";
import { capitalizedFileType, deleteFile } from "@/services/files.service";
import { ContextMenu } from "@components/library/ContextMenu";
import IconButton from "@components/library/IconButton";
import { ArrowUpRightIcon, PencilIcon } from "@components/library/Icons";
import { ToastVariant, hlToast, hlToastApiError } from "@components/library/Toast";
import { ClipboardCopyIcon, CogIcon, DotsVerticalIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { ArrowRightIcon } from "@heroicons/react/solid";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useRouter } from "next/router";
import { useState } from "react";
import { FILE_PAGES_HIDDEN_FROM_TREE } from "./TreeNodes";
import KeyboardShortcut from "@components/library/KeyboardShortcut";

interface Props {
  file: DirectoryFile | Directory;
  selected: boolean;
  startRenaming: () => void;
  href: string;
}

export const FileNodeMenu = ({ file, selected, startRenaming, href }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu.Root open={open} onOpenChange={(open) => setOpen(open)}>
      <DropdownMenu.Trigger asChild>
        <IconButton
          className={classNames(
            "m-1 shrink-0",
            open
              ? ""
              : selected
                ? "opacity-70 group-hover/node:opacity-100"
                : "hidden group-hover/node:inline-flex group-data-[state=open]/node:inline-flex",
          )}
          Icon={DotsVerticalIcon}
          size={20}
          active={open}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <FileNodeMenuItems
          file={file}
          selected={selected}
          menuType="dropdown"
          startRenaming={startRenaming}
          href={href}
        />
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

interface FileNodeMenuItemsProps {
  file: DirectoryFile | Directory;
  selected: boolean;
  menuType: "dropdown" | "context";
  startRenaming: () => void;
  href: string;
}

export const FileNodeMenuItems = ({ file, selected, menuType, startRenaming, href }: FileNodeMenuItemsProps) => {
  // Display the file pages that are hidden from the tree, in this dropdown menu, and settings.
  const pages = isDirectoryFile(file)
    ? filePagesForFileType(file.type).filter((page) => FILE_PAGES_HIDDEN_FROM_TREE.includes(page))
    : [];

  const settingsHref = useAppHref({
    type: "file",
    id: file.id,
    page: "settings",
  });
  const { onChange } = useDirectoryStructure();
  const { mutate: refreshDirectory } = useDirectory(isDirectoryFile(file) ? file.directory_id : null);

  const { setFileModalState } = useSidebarContext();

  const confirm = useConfirm();

  const { slug } = useActiveOrganization();

  const isDropdownMenu = menuType === "dropdown";
  const router = useRouter();

  return (
    <ContextMenu.Items
      isDropdownMenu={isDropdownMenu}
      align="start"
      sideOffset={4}
      // Above drawers at 1000
      className="z-[1010] min-w-[192px]"
      onClick={(event) => {
        // Prevent the click from being passed to the sidebar
        event.stopPropagation();
      }}
      collisionPadding={8}
    >
      {!isDirectoryFile(file) ? (
        <>
          <ContextMenu.Item
            withinDropdownMenu={isDropdownMenu}
            IconLeft={PlusIcon}
            onClick={() => {
              setFileModalState((state) => ({ open: true, type: "create-file", targetDirectory: file }));
            }}
          >
            New File
          </ContextMenu.Item>
          <Separator />
        </>
      ) : null}

      <ContextMenu.Item withinDropdownMenu={isDropdownMenu} IconLeft={ArrowUpRightIcon} target="_blank" href={href}>
        Open in new tab
      </ContextMenu.Item>
      <ContextMenu.Item
        withinDropdownMenu={isDropdownMenu}
        IconLeft={ClipboardCopyIcon}
        onClick={() => {
          navigator.clipboard.writeText(file.id);
          hlToast({
            title: "ID copied to clipboard",
            variant: ToastVariant.Success,
          });
        }}
      >
        Copy ID
      </ContextMenu.Item>

      {(["settings"] as const).some((page) => pages.includes(page)) ? <Separator /> : null}
      {pages.includes("settings") && (
        <ContextMenu.Item withinDropdownMenu={isDropdownMenu} IconLeft={CogIcon} href={settingsHref}>
          Settings
        </ContextMenu.Item>
      )}

      <Separator />

      <ContextMenu.Item withinDropdownMenu={isDropdownMenu} IconLeft={PencilIcon} onClick={startRenaming}>
        Rename
      </ContextMenu.Item>
      <ContextMenu.Item
        withinDropdownMenu={isDropdownMenu}
        IconLeft={ArrowRightIcon}
        onClick={() => {
          setFileModalState({
            open: true,
            type: "move",
            files: [isDirectoryFile(file) ? { type: "file", item: file } : { type: "directory", item: file }],
          });
        }}
      >
        Move to...
      </ContextMenu.Item>

      <ContextMenu.Item
        withinDropdownMenu={isDropdownMenu}
        IconLeft={TrashIcon}
        shortcut={["delete", "backspace"]}
        key="delete"
        onClick={async () => {
          if (isDirectoryFile(file)) {
            const fileType = file ? capitalizedFileType(file.type) : "File";
            try {
              await confirm({
                title: `Are you sure you want to delete this ${fileType}?`,
                content: "This action cannot be undone.",
                confirmationText: "Delete",
                cancellationText: "Cancel",
              });
              if (!file?.id) {
                throw new Error(`${fileType} ID not found: ${file?.id}`);
              }
              await deleteFile(file.id);
              if (selected) {
                router.push(`/${slug}`);
              }
              onChange([
                {
                  type: "delete",
                  item: { type: "file", item: file },
                },
              ]);
              refreshDirectory();
              hlToast({ title: `${fileType} deleted`, variant: ToastVariant.Success });
            } catch (e: any) {
              if (e !== undefined) {
                const detail = e?.response?.data?.detail;
                if (detail) {
                  hlToast({ title: detail.msg, description: detail.description, variant: ToastVariant.Error });
                } else {
                  throw e;
                }
              }
            }
          } else {
            // No need to confirm directory deletion as only empty directories can be deleted.
            try {
              await deleteDirectory(file.id);
              onChange([{ type: "delete", item: { type: "directory", item: file } }]);
              hlToast({ title: `Empty Directory '${file.name}' deleted`, variant: ToastVariant.Success });
            } catch (e) {
              console.log("Error deleting directory", e);
              hlToastApiError({ error: e, titleFallback: "Failed to delete directory" });
            }
          }
        }}
      >
        <span className="flex grow justify-start">Delete</span>{" "}
        <KeyboardShortcut className="opacity-50">⌫</KeyboardShortcut>
      </ContextMenu.Item>
    </ContextMenu.Items>
  );
};

const Separator = () => <DropdownMenu.Separator className="my-4 border-b border-stroke-base-2" />;
