export const EventPropagationShield = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;

  return (
    <div
      // Prevent events within the modals (mostly the DirectoryModal) from bubbling up to the tree
      onClick={(event) => {
        event.stopPropagation();
      }}
      onKeyDown={(event) => {
        event.stopPropagation();
      }}
    >
      {children}
    </div>
  );
};
