import { useSidebarContext } from "@/context/SidebarContextProvider";
import IconButton from "@components/library/IconButton";
import { PlusIcon } from "@heroicons/react/outline";
import Link from "next/link";

export const SidebarLabel = ({ href, children }: React.PropsWithChildren<{ href: string }>) => {
  const { setFileModalState } = useSidebarContext();
  // Designs have this expanding/collapsing. Really doesn't make sense to have that yet.
  return (
    <Link href={href} className="mx-8">
      <div className="group flex w-full items-center justify-between gap-8 rounded-ms px-8 py-[8px] text-10-10 font-bold uppercase text-text-base-4 hover:bg-background-base-325 hover:text-text-base-2">
        {children}
        <IconButton
          tooltip="New File"
          className="-my-4 -mr-4 opacity-0 transition-all group-hover:opacity-100"
          Icon={PlusIcon}
          size={20}
          shade="black"
          onClick={(e) => {
            e.preventDefault();
            setFileModalState({ open: true, type: "create-file" });
          }}
        />
      </div>
    </Link>
  );
};
