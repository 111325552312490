import { classNames } from "@/lib/utils";
import Button from "@components/library/Button";
import { ArrowRight } from "@phosphor-icons/react";
import { FREE_PLAN, LOGS_COUNTDOWN_THRESHOLD } from "./PricingPlans";
import { analytics } from "@/lib/analytics/analytics.client";

interface Props {
  logsUsage: number;
}

export const FreeTierUpsellBanner = ({ logsUsage }: Props) => {
  const logsRemaining = Math.max(0, FREE_PLAN.monthlyLogsLimit - logsUsage);

  let type: "default" | "logs-countdown" | "logs-exceeded";
  if (logsRemaining === 0) {
    type = "logs-exceeded";
  } else if (logsRemaining && logsRemaining < LOGS_COUNTDOWN_THRESHOLD) {
    type = "logs-countdown";
  } else {
    type = "default";
  }

  return (
    <div
      className={classNames(
        "flex flex-col gap-12 rounded-lg p-12",
        type === "default" && "bg-background-base-325",
        type === "logs-countdown" && "border border-[#A155EC] bg-background-premium",
        type === "logs-exceeded" && "border border-[#A155EC] bg-background-premium",
      )}
    >
      <div className="flex flex-col gap-8">
        <div
          className={classNames(
            "text-13-14 font-medium",
            type === "default" && "text-text-base-1",
            type === "logs-countdown" && "text-text-premium",
            type === "logs-exceeded" && "text-text-danger-2",
          )}
        >
          {type === "default" && "Free Tier"}
          {type === "logs-countdown" && `${logsRemaining} Logs remaining`}
          {type === "logs-exceeded" && `0 Logs remaining`}
        </div>
        <div
          className={classNames(
            "min-w-[168px] max-w-[75%] text-12-16",
            type === "default" && "text-text-base-1",
            type === "logs-countdown" && "text-text-premium",
            type === "logs-exceeded" && "text-text-premium",
          )}
        >
          {(type === "default" || type === "logs-countdown") && "Upgrade for unlimited Evals, Logs, and more seats"}
          {type === "logs-exceeded" && "Upgrade your plan to continue using Humanloop"}
        </div>
      </div>
      <div>
        <Button
          elevated={type === "default"}
          size={24}
          IconRight={ArrowRight}
          shade={type === "default" ? undefined : "black"}
          styling={type === "default" ? undefined : "solid"}
          onClick={() => {
            if (typeof Intercom !== "undefined") {
              Intercom("show");
            } else {
              window.open("mailto:support@humanloop.com");
            }

            analytics.track("Button Clicked", {
              location: "upsell-banner",
              purpose: "sidebar-logs",
              type,
            });
          }}
        >
          Talk to Sales
        </Button>
      </div>
    </div>
  );
};
