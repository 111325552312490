import { VersionStatus } from "@/types/app/version";
import dayjs from "dayjs";

// Taken from ag-grid-community, reproduced here to avoid the dependency.
export interface SortModelItem {
  /** Column Id to apply the sort to. */
  colId: string;
  /** Sort direction */
  sort: "asc" | "desc";
}

export interface CategoricalFilter {
  selectedOptions: string[];
  kind: "categorical";
}
export interface TextFilter {
  text: string[];
  kind: "text";
}
interface DateFilter {
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  kind: "date";
}

export type FilterModel = Record<string, CategoricalFilter | TextFilter | DateFilter>;

interface Filters {
  categorical: Record<string, CategoricalFilter>;
  text: Record<string, TextFilter>;
  date: Record<string, DateFilter>;
  other: FilterModel;
}

export const splitFilterModelsByType = (filterModel: FilterModel): Filters => {
  let categorical: Record<string, CategoricalFilter> = {};
  let text: Record<string, TextFilter> = {};
  let date: Record<string, DateFilter> = {};
  let other: FilterModel = {};

  for (const [key, value] of Object.entries(filterModel)) {
    switch (value.kind) {
      case "categorical":
        categorical[key] = value;
        break;
      case "text":
        text[key] = value;
        break;
      case "date":
        date[key] = value;
        break;
      default:
        other[key] = value;
    }
  }

  return { categorical, text, date, other };
};

/**
 * Returns a new UrlSearchParams object with the sort and filter parameters added.
 */
export const addSortAndFilterURLSearchParams = (
  baseParams: URLSearchParams,
  {
    ids,
    search,
    metadataSearch,
    versionsFilter,
    startDate,
    endDate,
    sortModel,
    filterModel,
  }: {
    ids?: string[];
    search?: string;
    metadataSearch?: string;
    versionsFilter?: VersionStatus | null;
    startDate?: dayjs.Dayjs;
    endDate?: dayjs.Dayjs;
    sortModel?: SortModelItem[];
    filterModel?: FilterModel;
  },
): URLSearchParams => {
  const params = new URLSearchParams(baseParams);
  if (ids && ids.length > 0) {
    ids.forEach((id) => params.append("id", id));
  }
  if (search && search.length > 0) {
    params.append("search", search);
  }
  if (metadataSearch && metadataSearch.length > 0) {
    params.append("metadata_search", metadataSearch);
  }
  if (startDate) {
    params.append("start_date", startDate.format("YYYY-MM-DD"));
  }
  if (endDate) {
    params.append("end_date", endDate.format("YYYY-MM-DD"));
  }

  sortModel?.forEach(({ colId, sort }) => {
    params.append("sort", `${colId}.${sort}`);
  });

  if (filterModel) {
    Object.entries(filterModel).forEach(([colId, filter]) => {
      switch (filter.kind) {
        case "categorical":
          const { selectedOptions } = filter as { selectedOptions: string[] };
          selectedOptions.forEach((option) => params.append(`${colId}_filter`, option));
          break;
        case "text":
          const { text } = filter as { text: string[] };
          text.forEach((t) => params.append(`${colId}`, t));
          break;
        // TODO: Consider if date filter handling should be done here. Currently done by passing in `startDate` and `endDate` explicitly and handled above.
        // case "date":
        //   const { startDate, endDate } = filter as { startDate: dayjs.Dayjs | null; endDate: dayjs.Dayjs | null };
        //   if (startDate) {
        //     params.append("start_date", startDate.format("YYYY-MM-DD"));
        //   }
        //   if (endDate) {
        //     params.append("end_date", endDate.format("YYYY-MM-DD"));
        //   }
        //   break;

        default:
          console.log("Unhandled filter kind", filter);
      }
    });
  }

  if (versionsFilter) {
    params.append("version_status", versionsFilter.toString());
  }

  return params;
};
