import { useSidebarContext } from "@/context/SidebarContextProvider";
import { IconButtonProps } from "@components/library/IconButton";
import { IconButtonWithShortcut } from "@components/library/IconButtonWithShortcut";
import KeyboardShortcut, { ModKey } from "@components/library/KeyboardShortcut";
import { Tooltip } from "@components/library/Tooltip";
import { SidebarSimple } from "@phosphor-icons/react";
import { ForwardedRef, forwardRef } from "react";

type Props = Omit<IconButtonProps, "Icon" | "size">;

const SidebarToggleButtonComponent = (props: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  return (
    <IconButtonWithShortcut
      shortcut={"mod+."}
      {...props}
      Icon={SidebarSimple}
      size={24}
      shade="gray"
      // @ts-ignore -- don't know why this is complaining..
      ref={ref}
      disableFocus
    />
  );
};

export const SidebarToggleIconButton = forwardRef<HTMLButtonElement, Props>(SidebarToggleButtonComponent);

export const SidebarToggleButton = () => {
  const { sidebarCollapsed, toggleSidebarRef } = useSidebarContext();

  return (
    <Tooltip
      content={
        <div className="flex gap-8">
          <div>{sidebarCollapsed ? "Expand" : "Collapse"} sidebar</div>
          <SidebarToggleButtonShortcut />
        </div>
      }
    >
      <SidebarToggleIconButton onClick={() => toggleSidebarRef.current?.()} />
    </Tooltip>
  );
};

const SidebarToggleButtonShortcut = () => (
  <div className="flex items-center gap-2">
    <KeyboardShortcut size={16}>
      <ModKey size={11} />
    </KeyboardShortcut>
    <KeyboardShortcut size={16}>.</KeyboardShortcut>
  </div>
);
