import { useSidebarContext } from "@/context/SidebarContextProvider";
import useConfirm from "@/context/useConfirm";
import { useCurrentLocationFromHref } from "@/lib/path-utils";
import { useHasScrolled } from "@/lib/useHasScrolled";
import { classNames } from "@/lib/utils";
import {
  moveItemsAndHandleStructureUpdates,
  useDirectories,
  useDirectoryStructure,
} from "@/services/directories.service";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { NodeApi, Tree, CursorProps } from "react-arborist";
import useResizeObserver from "use-resize-observer";
import {
  getFileOrDirectoryFromNode,
  getRootDirectory,
  NewFileNodeItem,
  NodeRenderer,
  TREE_INDENT,
  TREE_PADDING,
  TreeNodeData,
  useDirectoryTree,
} from "./TreeNodes";

/** Disable drop targets.
 *
 * react-arborist seems to prevent some by default (like a directory into itself etc.)
 */
export const shouldPreventDrop = ({
  parentNode,
  dragNodes,
}: {
  parentNode: NodeApi<TreeNodeData>;
  dragNodes: NodeApi<TreeNodeData>[];
}) => {
  // Parent node is the root directory. This is always ok.
  if (parentNode.data.nodeType === undefined) {
    return false;
  }

  // Prevent dropping if:
  const shouldPrevent =
    // For directories: prevent dropping into descendants or immediate parent (no-op)
    dragNodes.some(
      (dragNode) =>
        dragNode.data.nodeType === "directory" && (dragNode.isAncestorOf(parentNode) || dragNode.id === parentNode.id),
    );
  return shouldPrevent;
};

export const DirectoryTree = () => {
  const currentLocation = useCurrentLocationFromHref();

  const { treeRef } = useSidebarContext();

  const selectedNode = currentLocation?.id;

  const {
    tree,
    isLoading,
    isError,
    onChange: onDirectoryStructureChange,
  } = useDirectoryTree({
    selectedNode,
  });

  // Pattern to make the tree dynamic height: https://github.com/brimdata/react-arborist/issues/86#issuecomment-1653915522
  const { ref, height } = useResizeObserver();
  const { directories } = useDirectories();

  const [initialLoad, setInitialLoad] = useState(true);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (initialLoad && tree && selectedNode && treeRef.current) {
      setInitialLoad(false);
      treeRef.current?.focus(selectedNode);
    }
  }, [initialLoad, selectedNode, tree, treeRef]);

  // Manually attach ref of tree's rendered list to auto-animate tree changes.
  const [parent] = useAutoAnimate({ duration: 100 });
  useEffect(() => {
    if (treeRef.current) {
      parent(treeRef.current.listEl.current?.children[1] || null);
    }
  }, [parent, treeRef]);

  const [_, hasScrolled] = useHasScrolled<HTMLElement>({
    getScrollElement: () => treeRef.current?.listEl.current || null,
  });

  const { onChange } = useDirectoryStructure();
  const confirm = useConfirm();

  const handleMove = useCallback(
    async ({
      dragIds,
      dragNodes,
      parentId,
      parentNode,
      index,
    }: {
      dragIds: string[];
      dragNodes: NodeApi<TreeNodeData>[];
      parentId: string | null;
      parentNode: NodeApi<TreeNodeData> | null;
      index: number;
    }) => {
      // Don't allow moving in any of the ways that we prevent dropping.
      // If parentNode is null, we're moving the root node, which is allowed.
      if (parentNode && shouldPreventDrop({ dragNodes, parentNode })) {
        return;
      }

      // Filter out nodes that are not files or directories (new-file shouldn't be draggable so hopefully not an issue)
      // Filter out nodes that are not files or directories (new-file shouldn't be draggable so hopefully not an issue)
      const fileOrDirectories = dragNodes
        .map(getFileOrDirectoryFromNode)
        .filter((fileOrDirectory) => fileOrDirectory !== null);

      const targetDirectory =
        parentNode && parentNode.data.nodeType === "directory" ? parentNode.data : getRootDirectory(directories || []);
      if (!targetDirectory) {
        return;
      }
      const targetDirectoryName = targetDirectory.name;
      const targetDirectoryId = targetDirectory.id;

      moveItemsAndHandleStructureUpdates({
        items: fileOrDirectories,
        targetDirectoryId,
        targetDirectoryName,
        confirm,
        onChange,
      });
    },
    [onChange, directories],
  );

  const debouncedHandleMove = useCallback(
    debounce(
      (event) => {
        handleMove(event);
      },
      16, // roughly 60fps
      { leading: true, trailing: true },
    ),
    [handleMove],
  );

  return (
    <div ref={ref} className={classNames("relative h-full min-h-0")}>
      {hasScrolled ? <div className="bg-stroke-base-25 h-1 animate-fade-in" /> : <div className="h-1" />}

      {/* Keep tree rendered even if there's no data so that refs can be properly associated. */}
      <Tree
        ref={treeRef}
        data={tree}
        rowHeight={28}
        width="100%"
        indent={TREE_INDENT}
        height={height}
        disableMultiSelection
        disableEdit
        openByDefault={false}
        selection={selectedNode}
        rowClassName="focus-visible:outline-none"
        // !overflow-x-hidden overrides { overflow: auto; } on the tree container, which fixes a horizontal scrollbar flicker while scrolling.
        className="scrollbar-thumb-rounded-full !overflow-x-hidden scrollbar-thin scrollbar-track-background-base-2 scrollbar-thumb-background-base-4"
        overscanCount={50} // Prevent items fading in when scrolling
        onMove={debouncedHandleMove}
        renderCursor={Cursor}
        disableDrop={shouldPreventDrop}
      >
        {NodeRenderer}
      </Tree>

      {/* Loading state for tree */}
      {!tree && (
        <div className="absolute inset-0 mx-16 flex flex-col gap-8 py-12">
          {[150, 150, 150, 150, 150, 150, 80, 150, 150, 60].map((width, i) => (
            <div
              key={i}
              className="h-20 animate-pulse rounded-ms bg-background-base-325"
              style={{
                width: `${width}px`,
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

function Cursor({ top, left, indent }: CursorProps) {
  // The logic of this isn't super clear/clean to me, but this is where I got to with some
  // experimenting with ~4 nested levels, thinking it looks ok.
  const positionLeft = Math.max(left + TREE_PADDING + 6, 16);
  const positionRight = TREE_PADDING;
  return (
    <div
      className={"pointer-events-none absolute z-10 h-0 border-t-2 border-stroke-secondary-3"}
      style={{ top, left: positionLeft, right: positionRight }}
    ></div>
  );
}
