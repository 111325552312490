import { classNames } from "@/lib/utils";
import { forwardRef } from "react";

export type TagSize = 18 | 20 | 24;

export interface TagProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: TagSize;
  shade?: "gray" | "violet" | "sky" | "red" | "green" | "yellow" | "navy" | "orange" | "blank";
  className?: string;
  children: React.ReactNode;
}

// Why does this exist? We have Tag v1.
export const Tag = forwardRef<HTMLDivElement, TagProps>(
  ({ size = 20, shade = "gray", className, children, ...props }: TagProps, ref) => {
    return (
      // It doesn't truncate when hovered
      <div className="flex [&:not(:hover)]:truncate" {...props} ref={ref}>
        <div
          {...props}
          className={classNames(
            "flex items-center gap-4 truncate font-medium",
            size === 18 && "h-[18px] rounded px-4 py-2 text-10-10",
            size === 20 && "h-20 rounded px-6 py-4 text-12-12",
            size === 24 && "h-24 rounded-[6px] px-6 py-[5px] text-14-14",
            shade === "gray" && "bg-gray-100 text-gray-950",
            shade === "violet" && "bg-background-premium text-text-premium",
            shade === "sky" && "bg-background-prompt-input text-text-prompt-input",
            shade === "red" && "bg-red-100 text-red-950",
            shade === "green" && "bg-green-100 text-green-950",
            shade === "yellow" && "bg-yellow-100 text-yellow-950",
            shade === "orange" && "bg-orange-100 text-orange-950",
            // TODO: remove when we've redisgned how we show a model config
            shade === "navy" && "bg-oldnavy-200 text-oldnavy-800",
            shade === "blank" && "border border-stroke-base-3 font-medium text-text-base-2",
            className,
          )}
        >
          {children}
        </div>
      </div>
    );
  },
);

Tag.displayName = "Tag";

export const ErrorTag = ({ size = 20, children, ...props }: Omit<TagProps, "shade">) => (
  <Tag size={size} shade={"red"} {...props}>
    {children}
  </Tag>
);

export default Tag;
