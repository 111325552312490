// Sidebar/Directory context provider

// Initially just to programmatically cause a file in the sidebar to be renamed,
// it now also has methods to add files and folders to the sidebar.

import { FilePage, useCurrentLocationFromHref } from "@/lib/path-utils";
import {
  Directory,
  DirectoryStructure,
  DirectoryStructureChange,
  FileOrDirectory,
  useDirectoryStructure,
} from "@/services/directories.service";
import { TreeNodeData } from "@components/layouts/Sidebar/DirectoryStructure/TreeNodes";
import React, { MutableRefObject, createContext, useContext, useRef, useState } from "react";
import { TreeApi } from "react-arborist";

type SidePanelState = "settings" | "versions" | "closed" | "details";

interface SideBarContextProps {
  renamingFileId: string | null;
  setRenamingFileId: React.Dispatch<React.SetStateAction<string | null>>;
  treeRef: MutableRefObject<TreeApi<TreeNodeData> | undefined>;
  directoryStructure: DirectoryStructure | undefined;
  rootDirectory: Directory | undefined;
  directoryStructureOnChange: (changes: DirectoryStructureChange[]) => void;
  fileModalState: FileModalState;
  setFileModalState: React.Dispatch<React.SetStateAction<FileModalState>>;
  /** Side panel is the right panel that shows settings, etc. */
  sidePanelOpen: boolean;
  setSidePanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /** Sidebar is the left panel that shows the file tree. */
  sidebarCollapsed: boolean;
  _setSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  /** Top bar actions are the buttons that appear on the right of the top bar. */
  topBarActionsTray: React.ReactNode;
  setTopBarActionsTray: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  // Use the toggle trigger to close the sidebars
  // This is a bit of a hack to allow any child of the context to toggle the sidebar open/close
  // because the sidebar closing logic is highly coupled to the rendering of the sidebar.
  toggleSidebarRef: MutableRefObject<() => void>;
  /* SidePanel is the right panel that shows content aware panels like settings, versions etc.
    It's managed at this level, but the content is dependent on context at lower levels that 
    isn't accessible form here */
  sidePanelState: SidePanelState;
  setSidePanelState: React.Dispatch<React.SetStateAction<SidePanelState>>;
}

const SidebarContext = createContext<SideBarContextProps | undefined>(undefined);

type FileModalState = {
  open: boolean;
} & (
  | {
      type: "create-file" | "create-directory";
      targetDirectory?: Directory;
    }
  | {
      type: "rename-directory";
      name?: string;
      directory: Directory;
    }
  | {
      type: "move";
      files: FileOrDirectory[];
    }
);

export const SidebarContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [renamingFileId, setRenamingFileId] = useState<string | null>(null);
  const { structure: directoryStructure, onChange: directoryStructureOnChange } = useDirectoryStructure();
  const rootDirectory = directoryStructure?.directories.find((directory) => directory.parent_id === null);
  const treeRef = useRef<TreeApi<TreeNodeData>>();
  const [fileModalState, setFileModalState] = useState<FileModalState>({ open: false, type: "create-file" });
  const currentLocation = useCurrentLocationFromHref();
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [topBarActionsTray, setTopBarActionsTray] = useState<React.ReactNode>(null);
  const [sidebarCollapsed, _setSidebarCollapsed] = useState(false);
  const [sidePanelState, setSidePanelState] = useState<SidePanelState>("closed");

  const toggleSidebarRef = useRef<() => void>(() => {});

  return (
    <SidebarContext.Provider
      value={{
        renamingFileId,
        setRenamingFileId,
        treeRef,
        directoryStructure,
        rootDirectory,
        directoryStructureOnChange,
        sidebarCollapsed,
        _setSidebarCollapsed,
        fileModalState,
        setFileModalState,
        toggleSidebarRef,
        sidePanelOpen,
        setSidePanelOpen,
        topBarActionsTray,
        setTopBarActionsTray,
        sidePanelState,
        setSidePanelState,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useSidebarContext must be used within a SidebarContextProvider");
  }
  return context;
};
