import { Directory, createDirectory, renameDirectory, updateDirectory } from "@/services/directories.service";
import { shouldWarnOnPathChange } from "@/services/files.service";
import Alert, { AlertVariant } from "@components/library/Alert";
import { RadixAppDialog } from "@components/library/AppDialog";
import Button from "@components/library/Button";
import { ToastVariant, hlToast, hlToastApiError } from "@components/library/Toast";
import { FormikTextInput } from "@components/molecules/FormikInput";
import { CheckIcon } from "@heroicons/react/outline";
import { Form, Formik } from "formik";
import { useEffect, useId } from "react";

interface CreateProps {
  mode: "create";
  onCreate: (directory: Directory) => void;
  parent: Directory;
}

interface RenameProps {
  mode: "rename";
  onRename: (directory: Directory) => void;
  directory: Directory;
}

type Props = (CreateProps | RenameProps) &
  Pick<React.ComponentPropsWithoutRef<typeof RadixAppDialog>, "open" | "onClose">;

export const DirectoryModal = ({ open, onClose, ...props }: Props) => {
  // Focus on input on open
  // Required as hack around Radix bug where focus is grabbed by close button
  // See: https://github.com/radix-ui/primitives/issues/1615
  const id = useId();
  useEffect(() => {
    if (open) {
      const input = document.getElementById(id);
      if (input) {
        const timeout = setTimeout(() => {
          input?.focus();
        }, 0);
        return () => clearTimeout(timeout);
      }
    }
  }, [id, open]);

  const shouldWarn =
    "directory" in props
      ? shouldWarnOnPathChange({
          type: "directory",
          item: props.directory,
        })
      : false;

  return (
    <RadixAppDialog
      open={open}
      onClose={onClose}
      title={props.mode === "create" ? "Create Directory" : "Rename Directory"}
      description={
        shouldWarn && props.mode === "rename" ? (
          <Alert
            size="sm"
            title={`Renaming this Directory can break your API calls`}
            description={"Any API calls using the Directory name in the path will need to be updated."}
            variant={AlertVariant.Error}
          />
        ) : null
      }
      className="max-w-lg"
    >
      <Formik
        initialValues={{
          name: props.mode === "create" ? "" : props.directory.name,
        }}
        onSubmit={async (values, { setErrors }) => {
          if (props.mode === "create") {
            try {
              const directory = (await createDirectory({ name: values.name, parent_id: props.parent.id })).data;
              // TODO: Handle error and success.
              props.onCreate(directory);
              hlToast({ title: `Directory '${directory.name}' created`, variant: ToastVariant.Success });
              onClose?.();
            } catch (error) {
              console.log(error);
              hlToastApiError({ error, titleFallback: "Failed to create directory" });
            }
          } else if (props.mode === "rename") {
            renameDirectory({
              directory: props.directory,
              name: values.name,
              onSuccess: (directory) => {
                props.onRename(directory);
                onClose?.();
              },
              onError: (error: any) => {
                if (error.response?.status === 409) {
                  setErrors({ name: "A directory with this name already exists" });
                }
              },
            });
          }
        }}
      >
        {({ values }) => (
          <Form className="flex flex-col gap-32">
            <FormikTextInput
              id={id}
              autoFocus
              name="name"
              label="Name"
              placeholder={props.mode === "create" ? "Untitled Directory" : props.directory.name}
            />
            <div className="flex justify-between">
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit" IconLeft={CheckIcon} styling="solid" shade="black">
                {props.mode === "create" ? "Create" : "Rename"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </RadixAppDialog>
  );
};
