import { Organization } from "@/services/organizations.service";
import OrganizationIcon from "@components/llama/Organizations/OrganizationIcon";
import Link from "next/link";

interface Props {
  organization?: Organization;
}

export const OrganizationButton = ({ organization }: Props) => {
  return (
    <Link
      // FYI hydration issues if this is empty string
      href={organization ? `/${organization.slug}` : "#"}
      className="inline-flex items-center gap-8 truncate rounded-ms border border-transparent p-4 pr-8 hover:border-stroke-base-4 focus:border-transparent focus:ring-2"
    >
      {organization ? (
        <>
          <OrganizationIcon organizationIcon={organization.icon} organizationId={organization.id} size={24} />
          <div className="truncate text-13-16 font-medium text-text-base-1">{organization.name}</div>
        </>
      ) : (
        <>
          <div className="h-24 w-24 animate-pulse rounded-full bg-background-base-35" />
          <div className="h-16 w-80 animate-pulse bg-background-base-325" />
        </>
      )}
    </Link>
  );
};
