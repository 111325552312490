import { AxiosPromise, AxiosResponse } from "axios";
import { ApiService } from "./api.service";
import {
  Datapoint,
  Dataset,
  DatasetRequest,
  DatasetResponse,
  UpdateDatasetRequest,
  parseDatasetResponse,
} from "@/types/app/dataset";
import useSWR, { SWRConfiguration } from "swr";
import { Page } from "@/types/generic";
import { getAuthToken } from "@/lib/use-auth";
import useSWRInfinite, { SWRInfiniteConfiguration } from "swr/infinite";

export const upsertDataset = async (
  request: DatasetRequest,
  versionId?: string,
  includeDatapointsInResponse?: boolean,
): AxiosPromise<Dataset> => {
  const urlParams = new URLSearchParams();
  if (versionId) {
    urlParams.append("version_id", versionId);
  }
  if (includeDatapointsInResponse) {
    urlParams.append("include_datapoints", "true");
  }
  const response: AxiosResponse<DatasetResponse> = await ApiService.post(
    `/v5/datasets?${urlParams.toString()}`,
    request,
  );
  return { ...response, data: parseDatasetResponse(response.data) };
};

export const moveDataset = async (datasetId: string, update: UpdateDatasetRequest): AxiosPromise<Dataset> => {
  const response: AxiosResponse<DatasetResponse> = await ApiService.patch(`/v5/datasets/${datasetId}`, update);
  return { ...response, data: parseDatasetResponse(response.data) };
};

/**
 * Create a new version of a dataset by adding datapoints defined in a CSV file
 * to an existing dataset version.
 *
 * The new version is returned.
 */
export const uploadDatapointsFromCsv = async (
  props: (
    | {
        /**
         * The dataset to create a new version for.
         *
         * A new version will be created for this dataset containing just the datapoints
         * from the CSV file.
         */
        datasetId: string;
      }
    | {
        /**
         * The existing dataset version to add the datapoints to.
         *
         * If not provided, a new version will be created with these datapoints only.
         */
        datasetId: string;
        addToVersionId: string;
      }
  ) & {
    file: File;
    commitMessage: string;
  },
): Promise<Dataset> => {
  const formData = new FormData();
  formData.append("file", props.file);
  formData.append("commit_message", props.commitMessage);

  const url =
    "addToVersionId" in props
      ? `/v5/datasets/${props.datasetId}/datapoints/csv?versionId=${props.addToVersionId}`
      : `/v5/datasets/${props.datasetId}/datapoints/csv`;

  const newVersions: AxiosResponse<DatasetResponse> = await ApiService.post(url, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return parseDatasetResponse(newVersions.data);
};

export const addDatapointsFromLogs = async (
  datasetId: string,
  datasetVersionId: string,
  logIds: string[],
  commitMessage: string,
): Promise<Dataset> => {
  const response = await ApiService.post(`/v5/datasets/${datasetId}/datapoints/logs?version_id=${datasetVersionId}`, {
    log_ids: logIds,
    commit_message: commitMessage,
  });

  return parseDatasetResponse(response.data);
};

const datapointFetcher = async ([url, token]: [string, string]): Promise<Page<Datapoint>> => {
  const response: Page<Datapoint> = await ApiService.getWithToken([url, token]);
  return response;
};

export const useDatapointsInfinite = (
  {
    datasetId,
    versionId,
    pageSize = 10,
  }: {
    datasetId: string;
    versionId: string;
    pageSize: number;
  },
  swrOptions: SWRInfiniteConfiguration<Page<Datapoint>> = {
    initialSize: 2, // Pre-fetch the next page
    parallel: true,
    revalidateFirstPage: false, // Defaults to `true`, leading to unnecessary re-fetching of the first page
  },
) => {
  const getURL = (pageIndex: number, previousPageData: Page<Datapoint> | null) => {
    return `/v5/datasets/${datasetId}/datapoints?page=${pageIndex + 1}&size=${pageSize}&version_id=${versionId}`;
  };

  const {
    data,
    error,
    mutate,
    size: numPages,
    setSize: setNumPages,
    isValidating,
  } = useSWRInfinite<Page<Datapoint>>(
    (pageIndex, previousPageData) => {
      const url = getURL(pageIndex, previousPageData);
      if (!url) return null;
      return [url, getAuthToken()];
    },
    datapointFetcher,
    swrOptions,
  );

  const datapoints = data ? data.concat().flatMap((page) => page.records) : [];
  const size = datapoints.length;
  const total = data ? data[0].total : 0;
  const hasLoadedAll = size == total;

  return {
    pagedDatapoints: datapoints,
    error,
    loading: !data && !error,
    mutate,
    numPages,
    setNumPages,
    isValidating,
    total,
    hasLoadedAll,
  };
};
