import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { DOCS_LINK } from "@/lib/constants";
import { getOrganizationSettingsHref } from "@/lib/path-utils";
import { ArrowUpRightIcon, CircleIcon } from "@components/library/Icons";
import KeyboardShortcut, { ModKey } from "@components/library/KeyboardShortcut";
import { OrganizationButton } from "@components/library/OrganizationButton";
import Tooltip from "@components/library/Tooltip";
import { OrganizationSwitcher } from "@components/llama/AppBar/OrganizationSwitcher";
import ProfileDropdown from "@components/llama/ProfileDropdown";
import { UserFeedback } from "@components/llama/UserFeedback";
import { BookOpenIcon, CogIcon, SearchIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import { memo, useEffect, useState } from "react";
import { DirectoryTree } from "./DirectoryStructure/DirectoryTree";
import { NavItem } from "./NavItem";
import { SearchDialog } from "./Search/SearchDialog";
import { SidebarLabel } from "./SidebarLabel";
import { SidebarToggleButton } from "./SidebarToggleButton";
import { FreeTierUpsellBanner } from "@components/llama/Pricing/FreeTierUpsellBanner";
import { FREE_PLAN } from "@components/llama/Pricing/PricingPlans";
import { LissajousLogo } from "@components/library/LissajousLogo";

interface SidebarProps {
  onToggleSidebar: () => void;
}

export const Sidebar = memo(({ onToggleSidebar }: SidebarProps) => {
  const { organization } = useActiveOrganization();
  const subscription = organization?.active_subscription;
  const onFreeTier = subscription?.product.name === "free";

  return (
    <div className="flex h-full w-full shrink-0 flex-col bg-background-base-3">
      <Header onToggleSidebar={onToggleSidebar} />
      <SidebarNav />
      {onFreeTier && (
        <div className="px-16">
          <FreeTierUpsellBanner logsUsage={subscription.billing_period_usage ?? 0} />
        </div>
      )}
      <Footer />
    </div>
  );
});
Sidebar.displayName = "Sidebar";

type HeaderProps = SidebarProps;

const Header = ({ onToggleSidebar }: HeaderProps) => {
  const { organization } = useActiveOrganization();

  return (
    <div className="flex h-48 gap-6 p-6">
      <div className="flex h-full grow gap-4 truncate p-2">
        <OrganizationButton organization={organization || undefined} />
        <OrganizationSwitcher />
      </div>
      <div className="flex shrink-0 items-center">
        <SidebarToggleButton />
      </div>
    </div>
  );
};

const SidebarNav = () => {
  const { slug } = useActiveOrganization();
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setSearchDialogOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  const router = useRouter();

  return (
    // This div is never meant to scroll, but scrollbars can appear as the Tree component temp resizes.
    <div className="flex grow flex-col gap-12 overflow-hidden">
      {/* Anchored */}
      <div className="flex flex-col px-8">
        <NavItem
          Icon={LissajousLogo}
          title="Home"
          href={`/${slug}`}
          active={router.pathname === "/[orgSlug]" && router.query.id === undefined}
        />
        <Tooltip
          content={
            <div className="flex items-center gap-6">
              Search all Files
              <KeyboardShortcut size={16}>
                <ModKey size={11} />
              </KeyboardShortcut>
              <KeyboardShortcut size={16}>K</KeyboardShortcut>
            </div>
          }
          side="right"
        >
          <div className="">
            <NavItem Icon={SearchIcon} title="Search" onClick={() => setSearchDialogOpen(true)} />
          </div>
        </Tooltip>
        <SearchDialog open={searchDialogOpen} setOpen={setSearchDialogOpen} />
        <NavItem
          Icon={BookOpenIcon}
          title="Library"
          href={slug ? `/${slug}/library` : undefined}
          active={router.pathname.startsWith("/[orgSlug]/library")}
        />
        <NavItem
          Icon={CogIcon}
          title="Organization settings"
          href={getOrganizationSettingsHref({ slug, page: "organization" })}
          active={router.pathname.startsWith("/[orgSlug]/account/")}
        />
        <NavItem Icon={ArrowUpRightIcon} target="_blank" title="Documentation" href={DOCS_LINK} />
      </div>
      {/* Files */}
      <div className="flex min-h-0 grow flex-col">
        <SidebarLabel href={`/${slug}`}>Files</SidebarLabel>
        <div className="flex min-h-0 grow flex-col">
          <DirectoryTree />
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="border-stroke-base-25 flex h-48 items-center justify-between gap-2 border-t py-20 pl-2 pr-8">
      <ProfileDropdown hideChevron avatarSize={24} placement="top-start" />
      <UserFeedback size={36} />
    </div>
  );
};
