import { AxiosPromise, AxiosResponse } from "axios";
import { ApiService } from "./api.service";
import {
  Evaluator,
  EvaluatorRequest,
  EvaluatorResponse,
  UpdateEvaluatorRequest,
  parseEvaluatorResponse,
} from "@/types/app/evaluator";
import { CreateEvaluatorLogRequest, CreateLogResponse, EvaluatorLog, Log, parseLogResponse } from "@/types/app/log";
import { getLog } from "./logs.service";

export const upsertEvaluator = async (request: EvaluatorRequest): AxiosPromise<Evaluator> => {
  const response: AxiosResponse<EvaluatorResponse> = await ApiService.post(`/v5/evaluators`, request);
  return { ...response, data: parseEvaluatorResponse(response.data) };
};

export const moveEvaluator = async (evaluatorId: string, update: UpdateEvaluatorRequest): AxiosPromise<Evaluator> => {
  const response: AxiosResponse<EvaluatorResponse> = await ApiService.patch(`/v5/evaluators/${evaluatorId}`, update);
  return { ...response, data: parseEvaluatorResponse(response.data) };
};

export const createEvaluatorLog = async (
  request: CreateEvaluatorLogRequest,
  versionId?: string,
): AxiosPromise<CreateLogResponse> => {
  // We should be passing in the evaluator ID (or path) as well as the version id as a query param
  const urlParams = new URLSearchParams(versionId ? { version_id: versionId } : {});
  return await ApiService.post(`/v5/evaluators/log?${urlParams.toString()}`, request);
};

/**
 * Helper method to create an Evaluator Log and retrieve the created Log.
 *
 * The retrieved log is required for the UI to update quickly without fetching a full page of Logs.
 * Our /log endpoints currently don't return the full created Log, even though they could.
 */
export const createEvaluatorLogAndFetchLog = async (
  request: CreateEvaluatorLogRequest,
  versionId?: string,
): Promise<EvaluatorLog> => {
  const response = await createEvaluatorLog(request, versionId);
  return (await getLog(response.data.id)) as EvaluatorLog;
};

export const getLatestEvaluatorLog = (evaluatorLogs: EvaluatorLog[]): EvaluatorLog | null => {
  const sortedLogs = evaluatorLogs.sort((a, b) => (a.created_at.isAfter(b.created_at) ? -1 : 1));
  return evaluatorLogs.length > 0 ? sortedLogs[0] : null;
};
