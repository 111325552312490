import Button, { ButtonSize } from "@components/library/Button";
import IconButton from "@components/library/IconButton";
import { CheckCircleIcon } from "@components/library/Icons";
import { FormikTextarea } from "@components/molecules/FormikInput";
import { XIcon } from "@heroicons/react/outline";
import * as Popover from "@radix-ui/react-popover";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { Form, Formik, useField } from "formik";
import { useAuth } from "lib/use-auth";
import { classNames, sleep } from "lib/utils";
import { posthog } from "posthog-js";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import * as Yup from "yup";

const EMOJIS = ["🤩", "🤔", "😕", "😭"];

interface Props {
  size: ButtonSize;
}

export const UserFeedback = ({ size }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  useHotkeys(
    "esc",
    () => {
      setOpen(false);
    },
    { enableOnFormTags: ["TEXTAREA"] },
  );

  return (
    <>
      <Popover.Root open={open}>
        <Popover.Trigger asChild>
          <Button
            styling="ghost"
            size={24}
            onClick={() => {
              setOpen(!open);
            }}
            className="px-4 opacity-80 hover:opacity-100"
          >
            Feedback
          </Button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="z-50 flex w-[280px] flex-col rounded bg-white px-10 py-8 shadow-c transition-all data-state-open:animate-fade-in"
            align="start"
            sideOffset={8}
            alignOffset={-90}
            side="top"
            collisionPadding={8}
          >
            <UserFeedbackForm setOpen={setOpen} />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </>
  );
};

export const UserFeedbackForm = ({
  setOpen,
  title = "Provide feedback",
  placeholder = "How could this page be improved?",
}: {
  setOpen?: (open: boolean) => void;
  title?: string;
  placeholder?: string;
}) => {
  const { user: user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  return (
    <Formik
      initialValues={{ feedback: "", emoji: EMOJIS[Math.floor(Math.random() * EMOJIS.length)] }}
      validateOnBlur={false}
      onSubmit={async (values, { setStatus }) => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        const properties = {
          feedback: values.feedback,
          emoji: values.emoji,
          user: user,
          page: window.location.pathname,
          url: window.location.href,
        };
        const result = await fetch("/api/slack", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(properties),
        });

        // Leaving this in case it works but all signs point to no. So delete in the future.
        if (posthog.__loaded) {
          const result = await posthog.capture("feedback", properties);
          setStatus("success");
        } else {
          console.log("Posthog not loaded");
        }
        await sleep(500);
        setIsSubmitting(false);
      }}
      validationSchema={Yup.object({
        feedback: Yup.string().required("Required"),
      })}
    >
      {({ values, isValid, validateForm, isSubmitting, errors, status, resetForm, submitForm }) => {
        return (
          // @ts-ignore
          <Form>
            <div className="flex flex-col justify-between ">
              <div className="flex items-center justify-between">
                <div className="pl-2 text-13-16 font-bold">{status === "success" ? "" : title}</div>
                {setOpen && (
                  <IconButton
                    Icon={XIcon}
                    onClick={() => {
                      setOpen(false);
                      status === "success" && resetForm();
                    }}
                  />
                )}
              </div>
              {status === "success" ? (
                <div className="flex flex-col items-center gap-12 p-12 pb-32">
                  <CheckCircleIcon className=" h-20 w-20 text-oldblue-600" />

                  <div className="text-13-16 font-bold">Thank you!</div>
                  <div className="max-w-[180px] text-center text-12-16 ">
                    Feedback is important.
                    <br /> Thanks for helping us improve.
                  </div>
                </div>
              ) : (
                <>
                  <div className="my-12 flex flex-col">
                    <FormikTextarea
                      onSubmit={submitForm}
                      autoFocus
                      rows={5}
                      placeholder={placeholder}
                      name="feedback"
                      className={isSubmitting ? "bg-oldgray-200" : ""}
                      disabled={isSubmitting}
                    />
                  </div>
                  <div className="flex justify-between pl-4">
                    <EmojiPicker name={"emoji"} handleSubmit={submitForm} />
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? "Sending..." : "Submit"}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

interface EmojiPickerProps {
  name: string;
  emojis?: string[];
  handleSubmit?: () => void;
}

const EmojiPicker = ({ name, emojis = EMOJIS, handleSubmit }: EmojiPickerProps) => {
  const [field, meta, helpers] = useField(name);
  const error = meta.touched && meta.error;
  return (
    <>
      <ToggleGroup.Root
        type="single"
        aria-label="Emoji"
        className="flex items-center gap-12"
        value={field.value}
        onValueChange={(value) => {
          if (value) helpers.setValue(value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSubmit && handleSubmit();
          }
        }}
      >
        {emojis.map((emoji) => (
          <ToggleGroup.Item
            key={emoji}
            value={emoji}
            className={classNames(
              "relative inline-flex h-24 w-24 select-none items-center justify-center rounded text-black hover:bg-oldgray-100 focus:outline-none focus:ring focus:ring-oldgray-200 active:bg-oldgray-200",
              field.value === emoji ? "scale-125" : " opacity-80 grayscale",
            )}
          >
            <span className="text-16-20">{emoji}</span>
          </ToggleGroup.Item>
        ))}
      </ToggleGroup.Root>
    </>
  );
};
