import { Lissajous } from "@components/library/Lissajous";
import { shuffleWithSeed } from "@components/library/utils/random";
import { Avatar } from "evergreen-ui";
import { classNames } from "lib/utils";
import { memo, useEffect, useMemo } from "react";
import { UrlIcon, useOrganizationIcon } from "services/organizations.service";

type OrganizationIconSize = 24 | 28 | 40 | 48 | 80;

interface Props {
  organizationIcon?: UrlIcon | null;
  organizationId?: string;
  size: OrganizationIconSize;
  className?: string;
}

const OrganizationIcon = ({ organizationIcon, organizationId, size, className }: Props) => {
  const { data } = useOrganizationIcon(organizationId || null);

  // TODO: Prevent objectUrl from changing when image does not change.
  const objectUrl = useMemo(() => {
    if (data) {
      return URL.createObjectURL(data);
    } else {
      return null;
    }
  }, [data]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    if (objectUrl) {
      return () => {
        return URL.revokeObjectURL(objectUrl);
      };
    }
  }, [objectUrl]);

  return (
    <>
      <div
        className={classNames(
          "flex shrink-0 items-center justify-center rounded-full bg-black text-white",
          size === 24 && "h-24 w-24",
          size === 28 && "h-28 w-28",
          size === 40 && "h-40 w-40",
          size === 48 && "h-48 w-48",
          size === 80 && "h-80 w-80",
          className,
        )}
      >
        {organizationIcon?.url && objectUrl ? (
          <Avatar
            key={objectUrl} // Key to ensure an attempt to load the new url is made, when the url changes.
            src={objectUrl}
            size={size}
          />
        ) : (
          <FallbackOrganizationIcon size={size} seed={organizationId || ""} />
        )}
      </div>
    </>
  );
};

export default memo(
  OrganizationIcon,
  // Trying this isEqual implementation to prevent icon from rerendering unnecessarily, but the icon still flashes briefly sometimes. I don't know the cause of that.
  (prevProps, nextProps) =>
    prevProps.organizationIcon?.url === nextProps.organizationIcon?.url && prevProps.size === nextProps.size,
);

const FallbackOrganizationIcon = ({ size, seed }: { size: OrganizationIconSize; seed: string }) => {
  const lissajousOptions = [
    { a: 1, b: 1, delay: 90 },
    { a: 1, b: 1, delay: 45 },
    { a: 1, b: 1, delay: 0 },
    { a: 1, b: 3, delay: 270 },
    { a: 1, b: 2, delay: 45 },
    { a: 1, b: 2, delay: 0 },
    // { a: 1, b: 3, delay: 0 }, // Consider removing this Humanloop-like (but candy wrapper-like variant)
    { a: 3, b: 2, delay: 0 },
    { a: 3, b: 4, delay: 0 },
  ];
  return (
    <Lissajous
      {...shuffleWithSeed(lissajousOptions, encodeStringToNumber(seed))[0]}
      className={classNames(
        size === 24 && "h-14 w-14",
        size === 28 && "h-16 w-16",
        size === 40 && "h-20 w-20",
        size === 48 && "h-24 w-24",
        size === 80 && "h-40 w-40",
      )}
    />
  );
};

/**
 * Encode a string to a number
 */
function encodeStringToNumber(str: string): number {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}
