export const FREE_PLAN = {
  name: "free",
  membersLimit: 2,
  runsLimit: 50,
  monthlyLogsLimit: 10000,
};

/** We start showing the how many Logs remain when below this threshold. */
export const LOGS_COUNTDOWN_THRESHOLD = 2000;

/** We start showing the how many Runs remain when below this threshold. */
export const RUNS_COUNTDOWN_THRESHOLD = 10;

/** We start showing the how many Members remain when below this threshold. */
export const MEMBERS_COUNTDOWN_THRESHOLD = 2; // Equal to limit, so always shown.

export const PLANS = [FREE_PLAN];
