import { classNames } from "@/lib/utils";
import { UnstyledButtonBase, UnstyledButtonBaseProps } from "@components/library/Button";

interface NavItemProps extends UnstyledButtonBaseProps {
  Icon: (props: React.ComponentProps<"svg">) => JSX.Element;
  title: string;
  active?: boolean; // Manual override to set the active state (otherwise based on CSS)
  className?: string;
}

export const NavItem = ({ Icon, title, href, onClick, active, className, ...props }: NavItemProps) => {
  return (
    <UnstyledButtonBase
      href={href as string} // TypeScript doesn't like the conditional element type
      className={classNames(
        "group/navitem flex h-28 w-full select-none items-center gap-8 rounded-ms px-8 hover:bg-background-base-325 active:bg-background-base-35",
        className,
      )}
      onClick={onClick}
      {...props}
    >
      <Icon
        className={classNames(
          "h-14 w-14 shrink-0",
          active ? "text-icon-base-1" : "text-icon-base-3 group-hover/navitem:text-icon-base-1",
        )}
      />
      <div
        className={classNames(
          "truncate text-12-14 text-text-base-2 group-hover/navitem:text-text-base-1 active:text-text-base-1",
          active ? "font-bold " : "",
        )}
      >
        {title}
      </div>
    </UnstyledButtonBase>
  );
};
