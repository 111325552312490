import { useRef, useState, useCallback, useEffect } from "react";

interface UseHasScrolledOptions<T extends HTMLElement> {
  externalRef?: React.RefObject<T>;
  getScrollElement?: () => T | null;
}

/**
 * Hook to track whether an element has been scrolled.
 * Returns a tuple containing:
 * - ref to attach to the scrollable element
 * - boolean indicating if element has been scrolled
 * - function to manually reset scroll state
 */
export const useHasScrolled = <T extends HTMLElement>(options: UseHasScrolledOptions<T> = {}) => {
  const { externalRef, getScrollElement } = options;
  const internalRef = useRef<T>(null);
  const elementRef = externalRef || internalRef;
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollingElement = getScrollElement?.() || elementRef.current;
      if (!scrollingElement) return;

      if (scrollingElement.scrollTop > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    const scrollingElement = getScrollElement?.() || elementRef.current;
    if (scrollingElement) {
      scrollingElement.addEventListener("scroll", handleScroll);
      return () => scrollingElement.removeEventListener("scroll", handleScroll);
    }
  }, [getScrollElement, elementRef]);

  const resetScroll = useCallback(() => {
    setHasScrolled(false);
    const scrollingElement = getScrollElement?.() || elementRef.current;
    if (scrollingElement) {
      scrollingElement.scrollTop = 0;
    }
  }, [getScrollElement, elementRef]);

  return [elementRef, hasScrolled, resetScroll] as const;
};
